import { Component, OnInit } from '@angular/core';
import { Pallet, StoreModal } from '../../../modals/store.modal';
import { StatesService } from '../../../services/states.service';
// tslint:disable-next-line:no-implicit-dependencies
import { v4 as uuidv4 } from 'uuid';
// tslint:disable-next-line:no-implicit-dependencies
import * as _ from 'lodash';
import { FirebaseService } from '../../../services/firebase.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-add-shielf',
  templateUrl: './add-shielf.component.html',
  styleUrls: ['./add-shielf.component.scss'],
  providers: [MessageService],
})
export class AddShielfComponent implements OnInit {
  selectedStore: StoreModal;
  selectedShelf: Pallet;
  isAddNew = false;
  store: StoreModal[];

  constructor(
    private state: StatesService,
    private firebaseService: FirebaseService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    if(this.state.storesGlobalStates.length>0){
      this.store = this.state.storesGlobalStates;
    } else {
      this.firebaseService.getStores().subscribe(resposne => {
        this.store = resposne;
        this.state.storesGlobalStates =resposne
      })
    }

  }

  handleDeleteShelf(): void {
    const checkHasProduct = this.state.productsGlobal.some((product) =>
      product.pallets.some((palet) => palet.id === this.selectedShelf.id)
    );
    if (checkHasProduct) {
      this.message.add({
        severity: 'error',
        summary: 'Hata!',
        detail: 'Silmek istediğiniz rafta ürün kayıtlıdır.',
      });
      return;
    } else {
      this.selectedStore.pallets = this.selectedStore.pallets.filter(
        (s) => s.id !== this.selectedShelf.id
      );
      this.firebaseService
        .updateStore(this.selectedStore)
        .then(() => {
          this.message.add({
            severity: 'info',
            summary: 'Başarıyla Silindi',
            detail: `${this.selectedShelf.name}, ${this.selectedStore.name} Dükanından silindi.`,
          });
          this.resetSelectedStore();
        })
        .catch((error) => {
          this.message.add({
            severity: 'error',
            summary: 'Hata!',
            detail: `${error}`,
          });
        });
    }
  }

  handleAddShelf(): void {
    if(this.selectedShelf.name){
      this.selectedStore.pallets.push({
        id: uuidv4(),
        name: this.selectedShelf.name?.toLocaleUpperCase(),
      });
    } else {
      this.message.add({
        severity:'error',
        summary:'Hata',
        detail:'Lütfen raf adını giriniz.'
      })
      return;
    }

    this.selectedStore.pallets = _.uniqBy(this.selectedStore.pallets, 'name');
    this.firebaseService
      .updateStore(this.selectedStore)
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Başarıyla Eklendi',
          detail: `${this.selectedShelf.name.toUpperCase()}, ${
            this.selectedStore.name
          } Dükkanına eklendi.`,
        });
        this.resetSelectedStore();
      })
      .catch((error) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: `${error}`,
        });
      });
  }

  addShelf() {
    this.isAddNew = true;
    this.selectedShelf = new (class implements Pallet {
      id: string;
      name: string;
    })();
  }

  resetSelectedStore() {
    this.selectedShelf = null;
    this.selectedStore = null;
    this.isAddNew = false;
  }

  sortPallets() {
    this.selectedStore.pallets?.sort((a, b) => a.name.localeCompare(b.name));
  }
}
