<h2>Sipariş Geçmişi</h2>
<p-divider></p-divider>
<div class="export">
  <div>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-excel"
      (click)="exportExcel()"
      class="p-button-success p-mr-2"
      pTooltip="Excel Kaydet"
      tooltipPosition="bottom"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-bars"
      (click)="getAllOrders()"
      class="p-button-info p-mr-2"
      pTooltip="Tümünü Listele"
      tooltipPosition="bottom"
    ></button>
  </div>
  <div class="search-area">
    <input
      (keydown.enter)="search(text.value)"
      #text
      pInputText
      type="text"
      placeholder="Arama"
    />
    <button
      style="width: 50px"
      (click)="search(text.value)"
      pButton
      pRipple
      icon="pi pi-search"
    ></button>
    <button
      style="width: 50px"
      (click)="clear(); text.value = null"
      pButton
      pRipple
      icon="pi pi-times"
      class="p-button-help p-ml-1"
    ></button>
  </div>
</div>
<div *ngIf="!loading">
  <p-card
    *ngFor="let order of searchList"
    [style]="{ 'margin-bottom': '1em', padding: '0 5px' }"
  >
    <div class="top p-grid p-justify-between">
      <p>
        Sipriş No: <strong>{{ order.siparisNo }}</strong>
      </p>
      <p>
        Müşteri: <strong>{{ order.costumer?.name }}</strong>
      </p>
      <p>
        Tarih:
        <strong>{{ order.date | date: 'medium' }}</strong>
      </p>
    </div>
    <div class="header p-grid">
      <h4 class="p-lg-2">Malzeme İsmi</h4>
      <h4 class="p-lg-2">Malzeme Barkodu</h4>
      <h4 class="p-lg-1">Ürün Tipi</h4>
      <h4 class="p-lg-2">Çıkış Paletleri</h4>
      <h4 class="p-lg-1">Toplam Satış</h4>
      <h4 class="p-lg-1">Fiyat {{ order.item.costCurrency.name }}</h4>
      <h4 class="p-lg-1">Fiyat TL</h4>
      <h4 class="p-lg-1">İşlemi Yapan</h4>
      <h4 class="p-lg-1">İşlem</h4>
    </div>
    <div class="content p-grid p-align-center">
      <p class="p-lg-2">{{ order.item.name }}</p>
      <p class="p-lg-2">{{ order.item.barcodeCode }}</p>
      <p class="p-lg-1">{{ order.item.unitType.name }}</p>
      <p class="p-lg-2">
        <span *ngFor="let palet of order.locations">
          {{ palet.pallet.storeName }}-{{ palet.pallet.storeShelf }}:{{
            palet.quantity
          }}
          {{ order.item.unitType.symbol }}
          <span *ngIf="order.locations.length > 1">/</span>
        </span>
      </p>
      <p class="p-lg-1">{{ order.quantity }}</p>
      <p class="p-lg-1">
        {{ order.salesPrice.toFixed(2) }} {{ order.item.costCurrency.abbs }}
      </p>
      <p class="p-lg-1">{{ order.salesPriceTL.toFixed(2) }} TL</p>
      <p class="p-lg-1">{{ order.user?.name }}</p>
      <button
        *ngIf="!order.isReturn"
        (click)="confirmReturnItem(order)"
        pButton
        class="p-button-danger"
        label="İade et"
      ></button>
      <button
        *ngIf="!order.isReturn"
        (click)="print(order)"
        pButton
        class="p-button-warning"
        label="Yazdır"
      ></button>
      <p *ngIf="order.isReturn" class="p-lg-1">
        <span class="p-badge p-badge-warning">İade Edilmiş</span>
      </p>
    </div>
    <div *ngIf="order.note && order.note !== ''" class="note">
      <p>Note: {{order.note}}</p>
    </div>
  </p-card>
  <p-confirmDialog [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

</div>
<p-toast></p-toast>
<p-progressSpinner
  *ngIf="loading"
></p-progressSpinner>
<app-order-completed [isRePrint]='true' *ngIf='shareService.printPopUp'></app-order-completed>
