import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CostumerModal } from '../modals/costumer.modal';
import { ProductModal, ProductPalletModal } from '../modals/product.modal';
import { SalesItemModal } from '../modals/sales-item.modal';
import { StoreModal } from '../modals/store.modal';
import { CollectionsNames } from '../utils/collections-names';
import { StatesService } from './states.service';
import { SettingsModal } from '../modals/settings-modal';
import {
  OfferHistoryModal,
  OrderHistoryModal,
  StockHistoryModal,
} from '../modals/history-modals';
import { SharedService } from './shared.service';
import { UsersModal } from '../modals/users.modal';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private store: AngularFirestore,
    private stateService: StatesService,
    private sharedService: SharedService,
    private afAuth: AngularFireAuth
  ) {}
  // --------- Settings Functions --------------

  getSettings() {
    return this.store
      .collection<SettingsModal>(CollectionsNames.SETTINGS)
      .valueChanges({ idField: 'id' });
  }

  addUpdateSettings(settings: SettingsModal) {
    const payload = {
      ...settings,
      date: Date.now(),
    };
    return this.store.collection(CollectionsNames.SETTINGS).add(payload);
  }

  // --------- Store Functions --------------

  getStores() {
    // Get Stores information with updated value for each time, can watch with observable

    return this.store
      .collection<StoreModal>(CollectionsNames.STORES)
      .valueChanges({ idField: 'id' });
  }

  addStore(store: StoreModal) {
    // Add new store to database

    return this.store
      .collection<StoreModal>(CollectionsNames.STORES)
      .add(store);
  }

  async removeStore(store: StoreModal) {
    // Remove store from database

    return this.store
      .collection<StoreModal>(CollectionsNames.STORES)
      .doc(store.id)
      .delete();
  }

  updateStore(store: StoreModal) {
    // Update Store - Pass updated store as an argumanet

    return this.store
      .collection<StoreModal>(CollectionsNames.STORES)
      .doc(store.id)
      .update(store);
  }

  getStoreById(storeId: string) {
    // Get Store by ID

    return this.store
      .collection<StoreModal>(CollectionsNames.STORES)
      .doc(storeId).ref;
  }

  // --------- Costumer Functions --------------

  getCostumers() {
    // Get all costumers

    return this.store
      .collection<CostumerModal>(CollectionsNames.COSTUMERS)
      .valueChanges({ idField: 'id' });
  }

  addCostumer(costumer: CostumerModal) {
    // Add costumer, pass costumer modal as an object

    return this.store
      .collection<CostumerModal>(CollectionsNames.COSTUMERS)
      .add(costumer);
  }

  updateCostumer(costumer: CostumerModal) {
    // update costumer with updated costumer object as an argumanet

    return this.store
      .collection<CostumerModal>(CollectionsNames.COSTUMERS)
      .doc(costumer.id)
      .update(costumer);
  }

  removeCostumer(costumer: CostumerModal) {
    return this.store
      .collection<CostumerModal>(CollectionsNames.COSTUMERS)
      .doc(costumer.id)
      .delete();
  }

  // --------- Product Functions --------------

  getAllProducts() {
    // Get All Products

    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .valueChanges({ idField: 'id' });
  }

  getProductById(itemId: string) {
    // Get any product by id;

    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .doc(itemId).ref;
  }

  addProduct(product: ProductModal) {
    // Add new product

    const payload: ProductModal = {
      ...product,
      pallets: [],
    };

    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .add(payload);
  }

  updateProduct(product: ProductModal) {
    // update product, pass updated product as an argumant

    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .doc(product.id)
      .update(product);
  }

  removeProduct(product: ProductModal) {
    // delete product

    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .doc(product.id)
      .delete();
  }

  addStock(product: ProductModal, pallet: ProductPalletModal) {
    // Add updated product with new pallets array

    // Check if pallets exist in the product before
    const checkPallet = product.pallets.some((p) => p.id === pallet.id);

    // If pallets already added, update it with new value
    if (checkPallet) {
      product.pallets = product.pallets.map((p) => {
        if (p.id === pallet.id) {
          return {
            ...p,
            amountUnit: p.amountUnit + pallet.amountUnit,
          };
        }
        return p;
      });
    } else {
      // If not pallet added before
      product.pallets.push(pallet);
    }

    // update totalKG
    if (product.totalAmountKg) {
      product.totalAmountKg += (pallet.amountUnit / 1000) * product.kgPerUnit;
    } else {
      product.totalAmountKg = (pallet.amountUnit / 1000) * product.kgPerUnit;
    }
    return this.store
      .collection<ProductModal>(CollectionsNames.PRODUCTS)
      .doc(product.id)
      .update(product);
  }

  async saleProduct(soldItem: SalesItemModal): Promise<any> {
    // Sale product and decrease from the stock

    let payload: ProductModal;
    // Call data from database
    const ref = this.getProductById(soldItem.item.id);

    // take product for update.
    payload = await ref.get().then((r) => r.data());

    payload.pallets.forEach((p) => {
      soldItem.locations.forEach((l) => {
        if (l.pallet.id === p.id) {
          p.amountUnit -= l.quantity;
        }
      });
    });

    // Delete pallets if stock out
    payload.pallets = payload.pallets.filter(
      (product) => product.amountUnit > 0
    );

    return ref.update(payload);
  }

  // ----- Order History ----- //

  getAllOrderHistory() {
    // Get all completed orders.

    return this.store
      .collection<OrderHistoryModal>(CollectionsNames.ORDERS)
      .valueChanges({ idField: 'id' });
  }

  addOrderToHistory(
    order: SalesItemModal,
    siparisNo: string,
    costumer: CostumerModal,
    note: string
  ) {
    // Add completed order to history
    const date = Date.now();
    const payload: OrderHistoryModal = {
      ...order,
      siparisNo,
      date,
      costumer: costumer ? costumer : null,
      note: note ? note : null,
      user: this.stateService.user ? this.stateService.user : null,
      isReturn: false,
    };

    return this.store
      .collection<OrderHistoryModal>(CollectionsNames.ORDERS)
      .add(payload);
  }

  updateOrderHistory(order: OrderHistoryModal) {
    return this.store
      .collection(CollectionsNames.ORDERS)
      .doc(order.id)
      .update(order);
  }

  removeOrderFromHistory(order: SalesItemModal) {
    // Remove order from history database

    return this.store
      .collection<SalesItemModal>(CollectionsNames.ORDERS)
      .doc(order.id)
      .delete();
  }

  // ----- Loging History ----- //

  getAllLog() {
    return this.store
      .collection<ProductModal>(CollectionsNames.HISTORY)
      .valueChanges({ idField: 'id' });
  }

  addStoreLog(value: StoreModal) {
    const date = Date.now();
    const payload = {
      store: value,
      date,
      user: this.stateService.user,
    };
    const ref = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STORE_HISTORY).ref;

    return ref.collection(CollectionsNames.STORE_HISTORY).add(payload);
  }

  getStoreLogs() {
    return this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STORE_HISTORY)
      .collection(CollectionsNames.STORE_HISTORY)
      .valueChanges({ idField: 'id' });
  }

  addStockLog(value: any, pallet: any) {
    const date = Date.now();
    const payload = {
      product: value,
      addedStock: pallet,
      date,
      user: this.stateService.user,
    };
    const ref = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STOCK_HISTORY).ref;

    return ref.collection(CollectionsNames.STOCK_HISTORY).add(payload);
  }

  getStockLogs() {
    return this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STOCK_HISTORY)
      .collection<StockHistoryModal>(CollectionsNames.STOCK_HISTORY)
      .valueChanges({ idField: 'id' });
  }

  updateStockLog(stock: StockHistoryModal) {
    const stockLog = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STOCK_HISTORY).ref;

    return stockLog
      .collection(CollectionsNames.STOCK_HISTORY)
      .doc(stock.id)
      .update(stock);
  }

  addOfferLog(value: any) {
    const date = Date.now();
    const payload = {
      offer: value,
      date,
      user: this.stateService.user,
      rates: {
        dollar: this.sharedService.dollarRate,
        euro: this.sharedService.euroRate,
        profit: this.sharedService.profitRate,
      },
    };
    const ref = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.OFFER_HISTORY).ref;

    return ref.collection(CollectionsNames.OFFER_HISTORY).add(payload);
  }

  getOfferLogs() {
    return this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.OFFER_HISTORY)
      .collection<OfferHistoryModal>(CollectionsNames.OFFER_HISTORY)
      .valueChanges({ idField: 'id' });
  }

  getOfferLogByLimit(limit: number) {
    const ref = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.OFFER_HISTORY)
      .collection(CollectionsNames.OFFER_HISTORY).ref;
    return ref.orderBy('date', 'desc').limit(limit).get();
  }

  getStockLogByLimit(limit: number) {
    const ref = this.store
      .collection(CollectionsNames.HISTORY)
      .doc(CollectionsNames.STOCK_HISTORY)
      .collection(CollectionsNames.STOCK_HISTORY).ref;
    return ref.orderBy('date', 'desc').limit(limit).get();
  }
  getOrderLogByLimit(limit: number) {
    const ref = this.store.collection(CollectionsNames.ORDERS).ref;
    return ref.orderBy('date', 'desc').limit(limit).get();
  }

  getAllUser() {
    return this.store.collection<UsersModal>('users').valueChanges();
  }

  async getUser() {
    const user = await this.afAuth.currentUser;
    const ref = await this.store.collection<UsersModal>('users').doc(user.uid)
      .ref;
    return ref;
  }
}
