import { Component, OnInit } from '@angular/core';
import { StatesService } from '../../services/states.service';
import { ProductModal } from '../../modals/product.modal';
import { FormControl, FormGroup } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-delete-product',
  templateUrl: './delete-product.component.html',
  styleUrls: ['./delete-product.component.scss'],
  providers: [MessageService],
})
export class DeleteProductComponent implements OnInit {
  selectedProduct: ProductModal;
  newProductFrom: any;
  shared: any;

  constructor(
    public state: StatesService,
    private fs: FirebaseService,
    private message: MessageService
  ) {}

  ngOnInit(): void {}

  getProduct() {
    this.newProductFrom = new FormGroup({
      unitType: new FormControl(this.selectedProduct?.unitType.name),
      costCurrency: new FormControl(this.selectedProduct?.costCurrency.name),
      name: new FormControl(this.selectedProduct?.name),
      cost: new FormControl(this.selectedProduct?.cost),
      minimumAmount: new FormControl(this.selectedProduct?.minimumAmount),
      barcodeCode: new FormControl(this.selectedProduct?.barcodeCode),
      value1: new FormControl(this.selectedProduct?.value1),
      value2: new FormControl(this.selectedProduct?.value2),
      value3: new FormControl(this.selectedProduct?.value3),
      kgPerUnit: new FormControl(this.selectedProduct?.kgPerUnit),
      perItem: new FormControl(this.selectedProduct?.perItem),
    });
  }

  deleteProduct() {
    if (this.selectedProduct.pallets.length > 0) {
      this.message.add({
        severity: 'info',
        summary: 'Silme işlemi gerçekleştirilemez.',
        detail:
          'Bu ürünün stoklarda gözükmektedir. Stoklar tükenmeden ya da silinmeden ürün silme işlemi gerçekleştirilemez!',
      });
      return;
    }

    this.fs
      .removeProduct(this.selectedProduct)
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Silindi!',
          detail: `${this.selectedProduct.name} başarıyla silindi!`,
        });
        this.reset();
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err,
        });
      });
  }

  reset() {
    this.selectedProduct = null;
  }
}
