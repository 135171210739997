<div class="container">
  <h2>Ürün Paletlerini Değiştir</h2>
  <p-divider></p-divider>
  <div class="p-fluid p-my-4 p-input-filled p-lg-12">
    <span class="p-float-label">
      <p-dropdown
        placeholder="Ürün Seçiniz"
        [showTransitionOptions]="'100ms'"
        scrollHeight="150px"
        [hideTransitionOptions]="'100ms'"
        required
        [virtualScroll]="true"
        itemSize="30"
        [options]="products"
        [filter]="true"
        [(ngModel)]="selectedProduct"
        optionLabel="name"
        emptyFilterMessage="Ürün Bulunamadı!"
      ></p-dropdown>
    </span>
  </div>

  <ng-template [ngIf]="selectedProduct">
    <div *ngFor="let palet of selectedProduct?.pallets" class="p-grid">
      <div class="p-col-6 p-grid">
        <div class="p-col-4">
          <div class="p-fluid p-my-4 p-input-filled">
            <span class="p-float-label">
              <input
                disabled
                id="storeName"
                type="text"
                pInputText
                [value]="palet.storeName"
              />
              <label for="storeName">Dükkan Adı</label>
            </span>
          </div>
        </div>
        <div class="p-col-4">
          <div class="p-fluid p-my-4 p-input-filled">
            <span class="p-float-label">
              <input
                disabled
                id="rafAdı"
                type="text"
                pInputText
                [value]="palet.storeShelf"
              />
              <label for="rafAdı">Raf Adı</label>
            </span>
          </div>
        </div>
        <div class="p-col-4">
          <div class="p-fluid p-my-4 p-input-filled">
            <span class="p-float-label">
              <input
                #amount
               [value]='palet.amountUnit'
                required
                id="amount"
                type="number"
                pInputText
              />
              <label for="amount">Miktar</label>
            </span>
          </div>
        </div>
      </div>
      <p-divider layout="vertical"><em>Taşınacak</em></p-divider>
      <div class="p-col-5 p-grid p-align-center">
        <div class="p-fluid p-my-4 p-input-filled p-col-4">
          <span class="p-float-label">
            <p-dropdown
              placeholder="Dükkan Seçiniz"
              [showTransitionOptions]="'100ms'"
              scrollHeight="150px"
              [hideTransitionOptions]="'100ms'"
              required
              [virtualScroll]="true"
              itemSize="30"
              [options]="stores"
              [filter]="true"
              [(ngModel)]="selectedStore"
              optionLabel="name"
              emptyFilterMessage="Dükkan Bulunamadı!"
            ></p-dropdown>
          </span>
        </div>
        <div class="p-fluid p-my-4 p-input-filled p-col-4">
          <span class="p-float-label">
            <p-dropdown
              placeholder="Raf Seçiniz"
              [showTransitionOptions]="'100ms'"
              scrollHeight="150px"
              [hideTransitionOptions]="'100ms'"
              required
              [virtualScroll]="true"
              itemSize="30"
              [options]="selectedStore?.pallets"
              [filter]="true"
              [(ngModel)]="selectedPalet"

              optionLabel="name"
              emptyFilterMessage="Ürün Bulunamadı!"
            ></p-dropdown>
          </span>
        </div>
        <div class="p-fluid p-my-4 p-input-filled p-col-4">
          <button (click)='moveProduct(palet, +amount.value)' style='height: 100%' pButton class='p-button-success' label='Taşı'></button>
        </div>
      </div>
      <p-divider style='width: 100%' class='p-fluid' ></p-divider>
    </div>

  </ng-template>
</div>
<p-toast></p-toast>
