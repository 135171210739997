<p-dialog
  header="Sepete Ekle"
  position="top"
  [(visible)]="sharedService.cartPopUp"
  [baseZIndex]="10000"
  style="max-width: 1366px"
>

  <div class="p-d-flex p-justify-between">
    <h3>Ürün Adı: {{ product.name }}</h3>
  </div>
  <div *ngFor="let pallet of product.pallets">
    <p-card class="p-m-1">
      <form #addCartFrom (ngSubmit)="addToCart($event, pallet)">
        <div class="p-grid p-align-center">
          <div class="p-col-3">
            <p>
              Dükkan: <span appTextColor="primary">{{ pallet.storeName }}</span>
            </p>
          </div>
          <div class="p-col">
            <p>
              Raf: <span appTextColor="primary">{{ pallet.storeShelf }}</span>
            </p>
          </div>
          <div class="p-col-1">
            <p>
              KG:
              <span *ngIf="product.unitType.name !== 'Adet'" appTextColor="primary">{{
               ( pallet.amountUnit * product.kgPerUnit).toFixed(1)
              }}</span>
            </p>
          </div>
          <div class="p-col-3">
            <p>
              Raf Stok:
              <span appTextColor="primary"
                >{{ pallet.amountUnit }} - {{ product.unitType.name }}</span
              >
            </p>
          </div>

          <div class="p-col-3 p-fluid p-inputgroup">
            <span class="p-float-label">
              <input
                #inputAmount
                name="inputAmount"
                required
                id="salesAmount"
                type="number"
                min="0"
                [defaultValue]="0"
                [max]="pallet.amountUnit"
                pInputText
              />
              <label for="salesAmount">Satış Miktarı</label>
              <span class="p-inputgroup-addon">{{
                product.unitType.symbol
              }}</span>
            </span>
          </div>
          <div class="p-col">
            <button
              type="submit"
              pButton
              label="Ekle"
              class="p-button-info"
              [disabled]="pallet.amountUnit < 1 || +inputAmount.value === 0 || inputAmount.value > pallet.amountUnit && sharedService.isStockLocked"
            ></button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
</p-dialog>

<p-toast></p-toast>

<!--
<section>
  <p-card header="{{ product.name }}" subheader="{{ 'Stok Durumu: ' + product.stockNumber}}" styleClass="p-card-shadow">
    &lt;!&ndash;    <p-header>
          <img height="50px" alt="{{product.name + 'image'}}" [src]="product.imagePath" >
        </p-header>&ndash;&gt;
    <p>{{ product.barcodeCode }}</p>
    <ng-template pTemplate="footer">
      <button pButton pRipple type="button" label="Satış Yap" class="p-button-rounded"></button>
      <button pButton pRipple type="button" label="Stok Ekle" class="p-button-rounded p-button-warning"
              [style]="{'margin-left': '.em'}"></button>
    </ng-template>
  </p-card>
</section>
-->
<!--
<section>
  <p-card>
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red">Stok Kodu</h4>
        <p> {{product.stockCode}} </p>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red">Barkod Kodu</h4>
        <p appTextColor="secondary"> {{product.barcodeCode}} </p>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red">Malzeme Adı</h4>
       <p> {{product.name}}</p>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red"> Yeri</h4>
        <p>{{ product.store + '/' + product.pallets + '/' + product.shelf }}</p>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red">Stok Durumu</h4>
        <p>{{ product.stockNumber + ' ' + product.unit }}</p>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <h4 style="color: red">Ürün Fiyatı</h4>
        <p>{{ product.price }}</p>
      </div>
    </div>
  </p-card>
</section>-->
