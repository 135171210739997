import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { FirebaseService } from '../../services/firebase.service';
import { StatesService } from '../../services/states.service';
import { CurrenciesService } from '../../services/currencies.service';
import { Currency } from '../../modals/currency-modal';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    private firebaseService: FirebaseService,
    private stateService: StatesService,
    private currencyService: CurrenciesService,
    private tableService: TableService
  ) {}
  currencies: Currency;
  ngOnInit(): void {
    this.tableService.loading = true;
    this.firebaseService.getAllProducts().subscribe((response) => {
      this.stateService.productsGlobal = response;
      this.tableService.loading = false;
    }, error => {
      console.error(error);
      this.tableService.loading = false;
    });

    this.currencyService.getAllCurrency().subscribe((response) => {
      this.sharedService.currencyMB = response;
    });
    this.firebaseService
      .getStores()
      .subscribe((result) => (this.stateService.storesGlobalStates = result));
    this.firebaseService.getSettings().subscribe((response) => {
      response.sort((a, b) => b.date - a.date);
      const lastSettings = response[0];
      this.sharedService.dollarRate = lastSettings.dollarRate;
      this.sharedService.euroRate = lastSettings.euroRate;
      this.sharedService.profitRate = lastSettings.profitRate;
      this.sharedService.kdvRate = +lastSettings.kdvRate;
    });
    this.firebaseService.getCostumers().subscribe((response) => {
      this.stateService.costumerGlobal = response;
    });
  }
}
