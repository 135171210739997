import { Injectable } from '@angular/core';
import { ProductModal, ProductPalletModal } from '../modals/product.modal';
import { ProductTypes } from '../utils/product-types';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor() {}
  errorMessage = 'Stok Bilgisi Yok!';
  loading = false;

  // tslint:disable-next-line:variable-name
  private _arrayData: TableModal[];

  get arrayData(): TableModal[] {
    return this._arrayData;
  }

  set arrayData(value: TableModal[]) {
    this._arrayData = this.convertTable(value);
  }

  findStockStatus(minStock, stock) {
    if (stock <= 0 || !stock) {
      return {
        css: 'nostock',
        value: 'Stokta Yok!',
      };
    } else if (stock < minStock) {
      return {
        css: 'lowstock',
        value: 'Stok Azaldı!',
      };
    }
    return {
      css: 'instock',
      value: '-Stokta',
    };
  }

  convertPallets(pallets: ProductPalletModal[]) {
    if (pallets) {
      return pallets.map((p) => p.storeName + '-' + p.storeShelf).join(' / ');
    }
    return this.errorMessage;
  }

  getTotalUnit(pallets: ProductPalletModal[]): number {
    let total = 0;
    if (pallets) {
      pallets.forEach((p) => {
        total += p.amountUnit ? p.amountUnit : 0;
      });
      return total;
    }
    return null;
  }

  calculatePerItem(product: ProductModal) {
    if (product.perItem) {
      return +this.getTotalUnit(product.pallets) / +product.perItem;
    }
    return 0;
  }

  controlUnitIsExists(product: ProductModal): string {
    const totalUnit = this.getTotalUnit(product.pallets);
    if (totalUnit) {
      if (product.unitType.name === 'Plaka' || product.unitType.name === 'Adet') {
        return totalUnit + ' ' + product.unitType.symbol;
      }
       return +totalUnit / 1000 + ' Mt'
    }
    return this.errorMessage;
  }
  controlKgIsExists(product: ProductModal): string {
    const totalKg = this.getTotalUnit(product.pallets) * product.kgPerUnit;
    if (totalKg) {
      if (product.unitType.name === ProductTypes.PLAKA) {
        return totalKg.toFixed(1) + 'KG';
      }
      return (totalKg / 1000).toFixed(1) + ' KG';
    }
    return this.errorMessage;
  }

  convertTable(data): TableModal[] {
    return data.map((product: ProductModal) => {
      return {
        ...product,
        calculatedItem: this.calculatePerItem(product).toFixed(2),
        displayStore: this.convertPallets(product.pallets),
        totalKg:
          product.unitType.name === ProductTypes.ADET
            ? 0
            : this.controlKgIsExists(product),
        isUnitExist:
          product.unitType.name === ProductTypes.ADET
            ? '-'
            : this.controlUnitIsExists(product),
        stockStatusValue: this.findStockStatus(
          product.minimumAmount,
          this.getTotalUnit(product.pallets)
        ).value,
        stockStatusCss: this.findStockStatus(
          product.minimumAmount,
          this.getTotalUnit(product.pallets)
        ).css,
      };
    });
  }
}


export interface TableModal {
  id: string;
  barcodeCode: string;
  name: string;
  cost: number;
  oldCost: number;
  costCurrency: { name: string; abbs: string }; // kur dolar-tl
  unitType: {
    name: string;
    symbol: string;
    unit: string;
    carpan: number;
    input: number;
  }; // Metre - KG / Adet - KG
  minimumAmount: number;
  kgPerUnit: number;
  perItem?: number;
  pallets?: Array<ProductPalletModal>;
  totalAmountKg?: number; // KG
  totalAmountUnit?: number; // Metre or Adet
  value1?: number;
  value2?: number;
  value3?: number;
  calculatedItem: any,
  displayStore:string,
  totalKg:number,
  isUnitExist: any,
  stockStatusValue: any,
  stockStatusCss: any
}
