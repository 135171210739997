<p-dialog   header="Stok Ekle"
            position="top"
            [(visible)]="sharedService.selectCostumer"
            styleClass="pop-up-container">
<div class="container">
  <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Müşteri Seçiniz"
          [showTransitionOptions]="'100ms'"
          scrollHeight="150px"
          [hideTransitionOptions]="'100ms'"
          required
          [virtualScroll]="true"
          itemSize="30"
          [options]="stateService.costumerGlobal"
          [filter]="true"
          [(ngModel)]="selectedCustumer"
          optionLabel="name"
          emptyFilterMessage="Ürün Bulunamadı!"
        ></p-dropdown>
      </span>
  </div>
  <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <textarea
          [(ngModel)]="note"
          id="address"
          type="text"
          pInputTextarea
          rows="3"
        ></textarea>
        <label for="address">Not Ekle</label>
      </span>
  </div>
  <div class="p-fluid p-my-4 p-input-filled">
    <button [disabled]="!note && !selectedCustumer" (click)="saveNoteAndCostumer()" pButton label="Kaydet"></button>
  </div>
</div>
</p-dialog>
