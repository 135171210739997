import { Component, OnInit } from '@angular/core';
import {
  ProductModal,
  ProductPalletModal,
} from '../../../modals/product.modal';
import { StatesService } from '../../../services/states.service';
import { Pallet, StoreModal } from '../../../modals/store.modal';
import { FirebaseService } from '../../../services/firebase.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-product-move',
  templateUrl: './product-move.component.html',
  styleUrls: ['./product-move.component.scss'],
  providers: [MessageService],
})
export class ProductMoveComponent implements OnInit {
  products: ProductModal[];
  stores: StoreModal[];
  selectedStore: StoreModal;
  selectedPallet: ProductPalletModal;
  list: ProductModal[];
  movedStore: StoreModal;
  movedPallet: Pallet;
  constructor(
    private state: StatesService,
    private fs: FirebaseService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.products = this.state.productsGlobal;
    this.stores = this.state.storesGlobalStates;
  }

  getList() {
    this.list = this.products.filter((p) => {
      return p.pallets.some((l) => l.id === this.selectedPallet.id);
    });
  }

  findAmountInPallet(pallet: ProductPalletModal[]) {
    return pallet.filter((palet) => palet?.id === this.selectedPallet?.id);
  }

  movedItems() {
    this.list.forEach((product) => {
      const amount = this.findAmountInPallet(product.pallets)[0].amountUnit;
      product.pallets.forEach((palet) => {
        if (palet.id === this.movedPallet.id) {
          palet.amountUnit += amount;
        } else if (palet.id === this.selectedPallet.id) {
          palet.storeName = this.movedStore.name;
          palet.storeShelf = this.movedPallet.name;
          palet.id = this.movedPallet.id;
        }
        product.pallets = _.uniqBy(product.pallets, 'id');
      });
      this.fs
        .updateProduct(product)
        .then(() => {
          this.message.add({
            severity: 'success',
            summary: 'Taşındı!',
            detail: 'Ürünler Taşındı!',
          });
          this.clearScreen();
        })
        .catch((err) => {
          this.message.add({
            severity: 'error',
            summary: 'Hata!',
            detail: err,
          });
        });
    });
  }

  private clearScreen() {
    this.selectedPallet = null;
    this.selectedStore = null;
    this.movedPallet = null;
    this.movedStore = null;
    this.list = [];
  }
}
