<p-dialog
  header="Stok Ekle"
  position="top"
  [(visible)]="sharedService.addStockPopUp"
  styleClass="pop-up-container"
>
  <div class="content">
    <div class="p-fluid p-my-4 p-input-filled p-lg-12">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Ürün Seçiniz"
          [showTransitionOptions]="'100ms'"
          scrollHeight="150px"
          [hideTransitionOptions]="'100ms'"
          required
          [virtualScroll]="true"
          itemSize="30"
          [options]="state.productsGlobal"
          [filter]="true"
          [(ngModel)]="selectedProduct"
          (click)="cost = selectedProduct?.oldCost"
          optionLabel="name"
          emptyFilterMessage="Ürün Bulunamadı!"
        ></p-dropdown>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled p-lg-12">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Dükkan Seçiniz"
          required
          [options]="state.storesGlobalStates"
          [(ngModel)]="selectedStore"
          optionLabel="name"

        ></p-dropdown>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled p-lg-12">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Raf Seçiniz"
          [virtualScroll]="true"
          scrollHeight="100px"
          [filter]="true"
          required
          [options]="selectedStore?.pallets"
          [(ngModel)]="selectedShelf"
          optionLabel="name"
          emptyFilterMessage="Raf Bulunamadı!"
        ></p-dropdown>
      </span>
    </div>
    <div class="p-grid">
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <input
            required
            id="amount"
            type="number"
            [(ngModel)]="amount"
            pInputText
          />
          <label for="amount">Stok Miktarı</label>
          <span class="p-inputgroup-addon">{{
            selectedProduct?.unitType.symbol
          }}</span>
        </span>
      </div>
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <input
            required
            id="cost"
            type="number"
            [(ngModel)]="cost"
            pInputText
            [defaultValue]="selectedProduct?.oldCost"
          />
          <label for="cost">Birim Maliyeti</label>
          <span class="p-inputgroup-addon">{{
            selectedProduct?.costCurrency.abbs
          }}</span>
        </span>
      </div>
    </div>

    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-warning"
        (click)="sharedService.toggleAddStocksPopUp()"
        class="p-m-2"
        label="İptal"
      ></p-button>
      <p-button
        styleClass="p-button-success"
        (click)="handleAddStock()"
        [disabled]="checkFormValid()"
        type="submit"
        class="p-m-2"
        label="Kaydet"
      ></p-button>
    </div>
  </div>
</p-dialog>

<p-toast></p-toast>
