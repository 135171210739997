import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { SalesItemModal } from '../../modals/sales-item.modal';
import { SharedService } from '../../services/shared.service';
import { StatesService } from '../../services/states.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  constructor(
    public cartService: CartService,
    public sharedService: SharedService,
    public stateService: StatesService
  ) {}

  cartList: SalesItemModal[] = null;

  ngOnInit(): void {
    this.cartList = this.cartService.getCartItems();
    this.cartService.cartUpdated.subscribe((r) => {
      this.cartList = r;
    });
  }
  calculateItem = (item) => {
    return this.cartService.calculateCartItem(
      item.salesPrice,
      item.item.costCurrency.name
    );
  };
  getLocations(item: SalesItemModal): string {
    let result = '';

    item.locations.forEach((l, i, array) => {
      result += `${l.pallet.storeName}/ ${l.pallet.storeShelf}(${l.quantity} ${item.item.unitType.symbol} )`;
      if (i !== array.length - 1) {
        result += ' / ';
      }
    });
    return result;
  }
  deleteItem(item: SalesItemModal) {
    this.cartService.removeToCart(item);
    const payload = item.item;
    payload.pallets.forEach((palet) => {
      item.locations.forEach((l) => {
        if (l.pallet.id === palet.id) {
          palet.amountUnit += l.quantity;
        }
      });
    });
    this.stateService.updateProductPallet(payload);
  }

  updateSalesPrice(item: SalesItemModal, cost) {
    item.item.cost = cost;
    item.salesPrice = cost - 1 + this.sharedService.profitRate / 100;
    this.cartService.updateCartItem(item);
  }
}
