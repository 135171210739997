import { Injectable } from '@angular/core';
import { ProductTypes } from '../utils/product-types';
import { CurrencyNew } from '../modals/currency-modal';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  newItemPopUp = false;
  newCostumerPopUp = false;
  cartPopUp = false;
  addStockPopUp = false;
  printPopUp = false;
  profitRate = null;
  dollarRate = null;
  euroRate = null;
  settingsPopUp = false;
  kdvRate = null;
  offerScreen = false;
  selectCostumer = false;
  password = 1212;
  isStockLocked = true;
  currencyMB: CurrencyNew;
  currencies = [
    { name: 'Dollar', abbs: '$' },
    { name: 'Türk Lirası', abbs: '₺' },
    { name: 'Euro', abbs: '€' },
  ];
  units = [
    {
      name: ProductTypes.MIL,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.00617,
      input: 1,
      value1: 'Çap',
    },
    {
      name: ProductTypes.PLAKA,
      symbol: 'PL',
      unit: 'Plaka',
      carpan: 0.000008,
      input: 3,
      value1: 'Genişlik',
      value2: 'Uzunluk',
      value3: 'Kalınlık',
    },
    {
      name: ProductTypes.KARE,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.008,
      input: 1,
      value1: 'Kenar',
    },
    {
      name: ProductTypes.ALTIKOSE,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.0068,
      input: 1,
      value1: 'Kenar',
    },
    {
      name: ProductTypes.BORU,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.02505,
      input: 2,
      value1: 'Çap',
      value2: 'Kalınlık',
    },
    {
      name: ProductTypes.PROFIL,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.008,
      input: 3,
      value1: 'Yükseklik',
      value2: 'Genişlik',
      value3: 'Kalınlık',
    },
    {
      name: ProductTypes.KOSEBENT,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.008,
      input: 3,
      value1: 'Kenar 1',
      value2: 'Kenar 2',
      value3: 'Kalınlık',
    },
    {
      name: ProductTypes.ADET,
      symbol: 'Ad',
      unit: 'Adet',
      carpan: 1,
      input: 1,
      value1: 'Birim',
    },
    {
      name: ProductTypes.LAMA,
      symbol: 'MM',
      unit: 'Milimetre',
      carpan: 0.008,
      input: 2,
      value1: 'Kısa Kenar',
      value2: 'Uzun Kenar',
    },
  ];

  toggleNewItem = (): void => {
    this.newItemPopUp = !this.newItemPopUp;
  };

  toggleNewCostumer = (): void => {
    this.newCostumerPopUp = !this.newCostumerPopUp;
  };

  toggleAddToCart = (): void => {
    this.cartPopUp = !this.cartPopUp;
  };

  toggleSettingsPopUp = (): void => {
    this.settingsPopUp = !this.settingsPopUp;
  };

  toggleAddStocksPopUp = (): void => {
    this.addStockPopUp = !this.addStockPopUp;
  };

  togglePrintPopUp = (): void => {
    this.printPopUp = !this.printPopUp;
  };

  toggleSelectCostumer = (): void => {
    this.selectCostumer = !this.selectCostumer;
  };
  updateSettings = (form): void => {
    const { euroRate, dollarRate, profitRate, kdvRate } = form;
    this.profitRate = profitRate;
    this.dollarRate = dollarRate;
    this.euroRate = euroRate;
    this.kdvRate = kdvRate;
  };
}
