<div class="p-sm-12">
  <h2>Palet Ayarları</h2>
  <p-divider></p-divider>
  <div>
    <div class="p-grid p-justify-center p-mx-4">
      <div class="p-col-12 p-text-center">
        <p-dropdown
          [style]="{ 'min-width': '350px', margin: '0 10px' }"
          [options]="store"
          placeholder="Dükkan Seçiniz"
          [(ngModel)]="selectedStore"
          optionLabel="name"
          (click)="sortPallets()"
        ></p-dropdown>
      </div>
      <div *ngIf="selectedStore" class="p-sm-12 p-text-center">
        <p-dropdown
          [style]="{ 'min-width': '230px', margin: '0 5px' }"
          [options]="selectedStore?.pallets"
          placeholder="Palet Seçiniz"
          [(ngModel)]="selectedShelf"
          [showClear]="true"
          [filter]="true"
          [disabled]="isAddNew"
          optionLabel="name"
          emptyFilterMessage="Henüz Palet yok"
        ></p-dropdown>
        <button
          style="height: 100%"
          pButton
          type="button"
          [disabled]="!selectedStore"
          (click)="addShelf()"
          class="p-button-success"
          label="Palet Ekle"
          icon="pi pi-plus"
        ></button>
      </div>
    </div>
    <div *ngIf="selectedShelf && selectedStore"  class="p-grid p-justify-center">
      <div class="p-my-4">
        <span class="p-float-label">
          <input
            style="width: 360px"
            [(ngModel)]="selectedShelf.name"
            id="shelfName"
            type="text"
            pInputText
            (keyup.enter)="handleAddShelf()"
          />
          <label for="shelfName">Palet Adı</label>
        </span>
      </div>
    </div>
    <div class="p-m-4 p-grid p-justify-end">
      <button
        pButton
        class="p-button-warning p-mx-1"
        label="İptal"
        (click)="resetSelectedStore()"
      ></button>
      <button
        pButton
        class="p-button-danger p-mx-1"
        label="Rafı Sil"
        [disabled]="!selectedShelf || this.isAddNew"
        (click)="handleDeleteShelf()"
      ></button>
      <button
        (click)="handleAddShelf()"
        pButton
        label="Kaydet/Güncelle"
        icon="pi pi-save"
        class="p-button-success p-mx-1"
      ></button>
    </div>

  </div>
</div>
<p-toast></p-toast>
