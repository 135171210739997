import { Component, OnInit } from '@angular/core';
import {
  MenuItem,
  MessageService,
  ConfirmationService,
  PrimeIcons,
} from 'primeng/api';
import { SharedService } from '../../services/shared.service';
import { CartService } from '../../services/cart.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StatesService } from '../../services/states.service';
import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class HeaderComponent implements OnInit {
  menuItems: MenuItem[];
  isCartPage = false;
  constructor(
    private messageService: MessageService,
    private router: Router,
    private conformationService: ConfirmationService,
    private fs: FirebaseService,
    private authService:AuthService,
    public stateService: StatesService,
    public cartService: CartService,
    public sharedService: SharedService,

  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isCartPage = event.url === '/home/cart';
      });
  }

   ngOnInit(): void {
     this.user().then(response => {
       this.stateService.user = response.data()
       this.createMenuItems();

    })
   }

  async user() {
    const userRef = await this.fs.getUser();
    return userRef.get();

  }

  createMenuItems() {
    this.menuItems = [
      this.productMenu(),
      this.stockMenu(),
      this.currencyMenu(),
      this.costumerMenu(),
      this.storeMenu(),

      this.reportMenu(),
      this.userMenu(),
      {
        label: `Çıkış - ${this.stateService.user?.name}`,
        icon: PrimeIcons.POWER_OFF,
        command: () => this.authService.signOut(),
      },
    ];
  }

   userMenu() {
    return {
      label: 'Kullanıcı',
      icon: PrimeIcons.USER_PLUS,
      routerLink: ['user-settings']
    };
  }

   reportMenu() {
    return {
      label: 'Raporlar',
      icon: PrimeIcons.CHART_LINE,
      routerLink: ['reports']
    };
  }

   storeMenu() {
    return {
      label: 'Dükkan',
      icon: PrimeIcons.MICROSOFT,
      items: [
        {
          label: 'Dükkan Ayarları',
          icon: PrimeIcons.TABLET,
          routerLink: ['/home/add-new-store']
        },
        {
          label: 'Raf Ayarları',
          icon: PrimeIcons.ALIGN_JUSTIFY,
          routerLink: ['/home/add-new-shelf']
        },
        {
          label: 'Ürün Taşıma',
          icon: PrimeIcons.ANGLE_RIGHT,
          routerLink: ['move-item']
        },
        {
          label: 'Toplu Taşıma',
          icon: PrimeIcons.ANGLE_DOUBLE_RIGHT,
          routerLink: ['move-products']
        }
      ]
    };
  }

   costumerMenu() {
    return {
      label: 'Müşteri',
      icon: PrimeIcons.USER,
      items: [
        {
          label: 'Müşteri Ekle',
          icon: PrimeIcons.USER_PLUS,
          command: () => this.sharedService.toggleNewCostumer()
        },
        {
          label: 'Müşteri Ayarları',
          icon: PrimeIcons.USER_EDIT,
          routerLink: ['edit-costumer']
        }
      ]
    };
  }

   currencyMenu() {
    return {
      label: 'Maliyet',
      icon: PrimeIcons.MONEY_BILL,
      command: () => this.sharedService.toggleSettingsPopUp()
    };
  }

   stockMenu() {
    return {
      label: 'Stok',
      icon: PrimeIcons.HOME,
      items: [
        {
          label: 'Stok Ekle',
          icon: PrimeIcons.PLUS,
          command: () => this.sharedService.toggleAddStocksPopUp()
        },
        {
          label: 'Stok Sil',
          icon: PrimeIcons.TIMES,
          routerLink: ['stock-history']
        }
      ]
    };
  }

   productMenu() {
    return {
      label: 'Ürün',
      icon: PrimeIcons.TICKET,
      items: [
        {
          label: 'Ürün Ekle',
          icon: PrimeIcons.PLUS,
          command: () => this.sharedService.toggleNewItem()
        },
        {
          separator: true
        },
        {
          label: 'Ürün Düzenle',
          icon: PrimeIcons.PENCIL,
          routerLink: ['edit-product']
        },
        {
          label: 'Ürün Sil',
          icon: PrimeIcons.TIMES,
          routerLink: ['delete-product']
        }
      ]
    };
  }

  goToCart() {
    if (this.isCartPage) {
      this.router.navigate(['home', 'order']).then();
    } else {
      this.router.navigate(['home', 'cart']).then();
    }
  }

  printPage() {}

  completeOrder() {
    if (this.cartService.cartList.length > 0) {
      this.conformationService.confirm({
        header: 'Satış Tamamla',
        message: 'Satış işlemini tamamlamak istiyor musunuz?',
        acceptLabel: 'Evet, devam et.',
        rejectLabel: 'İptal Et',
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {
          this.sharedService.togglePrintPopUp();
        },
      });
    } else {
      this.messageService.add({
        summary: 'Sepet Boş',
        severity: 'error',
        detail: 'Lütfen önce sepete ürün ekleyiniz.',
      });
    }
  }
}
