import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';
import { UsersModal } from '../../modals/users.modal';
import {StatesService} from '../../services/states.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [MessageService]
})

export class UserSettingsComponent implements OnInit {

  constructor(private msg:MessageService, private auth:AuthService, public state:StatesService) { }
  settingForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    role: new FormControl({name:null, value:null}),
    name: new FormControl(''),
  });

  selectedUser: UsersModal;
  users: UsersModal[];
  isPasswordChange = false;
  newPassword: string;
  user: any;
  Roles = [
    {
    name: 'admin',
    value:'admin'
    },

    {
      name: 'user',
      value:'user'
    }
  ]

  ngOnInit(): void {
  }

  changeScreen() {
    this.isPasswordChange = true;
  }

  changeMyPassword() {
    this.auth.changePassword(this.newPassword)
      .then(() => {
        this.msg.add({
          severity: 'success',
          summary:'Şifre Değiştirildi.'
      })
      })
      .catch(err => {
        this.msg.add({
          severity: 'error',
          summary: 'hata',
          detail:err
      })
    })
  }

  createUser(): void {
    const { email, password, role, name } = this.settingForm.value;
    this.auth.createUser(email, password, role, name)
      .then(() => {
        this.msg.add({
          severity: 'success',
          summary: 'Kayıt Edildi.',
          detail: `${email} kullanıcısı, kayıt edildi.`
        });
        this.resetForm();
      })
      .catch(err => {
        this.msg.add({
          severity: 'error',
          summary: 'Hata!',
          detail:err
      })
      })


  }

  resetForm() {
    this.settingForm.reset();
  }
}
