export class StoreModal {
  id?: string;
  name: string;
  description: string;
  pallets?: Array<Pallet>;

  constructor(name: string, description: string, pallets: Array<Pallet>) {
    this.name = name;
    this.description = description;
    this.pallets = pallets;
  }
}

export interface Pallet {
  id: string;
  name: string;
}
