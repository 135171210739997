export class ProductTypes {
  public static MIL = 'Mil';
  public static KARE = 'Kare';
  public static ALTIKOSE = 'Altıköşe';
  public static BORU = 'Boru';
  public static PROFIL = 'Profil';
  public static PLAKA = 'Plaka';
  public static KOSEBENT = 'Köşebent';
  public static ADET = 'Adet';
  public static LAMA= 'Lama';
}
