import { Injectable } from '@angular/core';
import { StoreModal } from '../modals/store.modal';
import { ProductModal } from '../modals/product.modal';
import { CostumerModal } from '../modals/costumer.modal';
import { TableService } from './table.service';
import { UsersModal } from '../modals/users.modal';
import { OrderHistoryModal } from '../modals/history-modals';

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  constructor(private tableService: TableService) {}

  // tslint:disable-next-line:variable-name
  private _storesGlobalStates: StoreModal[] = [];
  // tslint:disable-next-line:variable-name
  private _productsGlobal: ProductModal[] = [];
  // tslint:disable-next-line:variable-name
  private _costumerGlobal: CostumerModal[] = [];

  // tslint:disable-next-line:variable-name
  private _user: UsersModal;

  // tslint:disable-next-line:variable-name
  private _ordersLog: OrderHistoryModal[];

  get ordersLog(): OrderHistoryModal[] {
    return this._ordersLog;
  }

  set ordersLog(value: OrderHistoryModal[]) {
    this._ordersLog = value;
  }

  get user(): UsersModal {
    return this._user;
  }

  set user(value: UsersModal) {
    this._user = value;
  }

  get storesGlobalStates(): StoreModal[] {
    return this._storesGlobalStates;
  }

  set storesGlobalStates(value: StoreModal[]) {
    this._storesGlobalStates = value;
  }

  get productsGlobal(): ProductModal[] {
    return this._productsGlobal;
  }

  set productsGlobal(value: ProductModal[]) {
    this._productsGlobal = value;
    this.tableService.arrayData = this.tableService.convertTable(value);
  }

  updateProductPallet(product: ProductModal) {
    this.productsGlobal = this.productsGlobal.map((p) => {
      if (p.id === product.id) {
        return product;
      }
      return p;
    });
    this.tableService.arrayData = this.tableService.convertTable(this.productsGlobal);
  }

  get costumerGlobal(): CostumerModal[] {
    return this._costumerGlobal;
  }

  set costumerGlobal(value: CostumerModal[]) {
    this._costumerGlobal = value;
  }
}
