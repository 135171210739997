import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StatesService } from '../../services/states.service';
import { CostumerModal } from '../../modals/costumer.modal';
import { MessageService } from 'primeng/api';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-edit-costumer',
  templateUrl: './edit-costumer.component.html',
  styleUrls: ['./edit-costumer.component.scss'],
  providers: [MessageService],
})
export class EditCostumerComponent implements OnInit {
  constructor(
    public state: StatesService,
    private fs: FirebaseService,
    private message: MessageService
  ) {}
  newCostumerForm: FormGroup;
  selectedCostumer: CostumerModal;
  ngOnInit(): void {}

  deleteCostumer() {
    this.fs
      .removeCostumer(this.selectedCostumer)
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Müşteri Silindi!',
          detail: `${this.selectedCostumer.name} silindi!`,
        });
        this.handleClear();
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err,
        });
      });
  }

  getCostumer() {
    this.newCostumerForm = new FormGroup({
      name: new FormControl(this.selectedCostumer?.name),
      officer: new FormControl(this.selectedCostumer?.officer),
      email: new FormControl(this.selectedCostumer?.email),
      address: new FormControl(this.selectedCostumer?.address),
      mobilePhone: new FormControl(this.selectedCostumer?.mobilePhone),
      homePhone: new FormControl(this.selectedCostumer?.homePhone),
    });
  }
  handleClear(): void {
    this.selectedCostumer = null;
    this.newCostumerForm.reset();
  }

  updateCostumer() {
    const payload = {
      ...this.selectedCostumer,
      ...this.newCostumerForm.value,
    };
    this.fs
      .updateCostumer(payload)
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Başarılı!',
          detail: `${this.selectedCostumer.name} güncellendi.`,
        });
        this.handleClear();
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata',
          detail: err,
        });
      });
  }
}
