<div class="container">
  <p-table
    #dt
    [value]="tableService.arrayData"
    dataKey="id"
    [rowHover]="true"
    styleClass="p-datatable-sm"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [loading]="tableService.loading"
    [paginator]="true"
    currentPageReportTemplate="Sayfa {first} / {last} gösteriliyor. Toplam {totalRecords} giriş var."
    [filterDelay]="0"
    [globalFilterFields]="['name', 'barcodeCode']"
    sortField="stockStatusValue"
  >
    <ng-template pTemplate="caption">
      <div class="table-header">
       <div>
         <button type="button" pButton icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="Excel Kaydet" tooltipPosition="bottom"></button>
         <button type="button" pButton (click)="sharedService.isStockLocked= !sharedService.isStockLocked" class="p-mr-2"  [ngClass]="{'p-button-warning': sharedService.isStockLocked, 'p-button-danger': !sharedService.isStockLocked}" pTooltip="Stock Kontrolü Kaldır" tooltipPosition="bottom" label="Teklif Modu"></button>
       </div>

        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            #searchInput
            (input)="dt.filterGlobal(searchInput.value.trim(), 'contains')"
            placeholder="Ara"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="barcodeCode">
          <div class="p-d-flex p-jc-between p-ai-center">
            Barkod
            <p-sortIcon field="barcodeCode"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="barcodeCode"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th style="width: 15rem" pSortableColumn="name">
          <div class="p-d-flex p-jc-between p-ai-center">
            Ürün Adı
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="name"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="isUnitExist">
          <div class="p-d-flex p-jc-between p-ai-center">
            Toplam
            <p-sortIcon field="isUnitExist"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="isUnitExist"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th style="width: 7rem" pSortableColumn="perItem">
          <div class="p-d-flex p-jc-between p-ai-center">
            Boy
            <p-sortIcon field="perItem"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="perItem"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>

        <th style="width: 7rem" pSortableColumn="kgPerUnit">
          <div class="p-d-flex p-jc-between p-ai-center">
            Birim KG
            <p-sortIcon field="kgPerUnit"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="kgPerUnit"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="totalKg">
          <div class="p-d-flex p-jc-between p-ai-center">
            Toplam KG
            <p-sortIcon field="totalKg"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="totalKg"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="pallets">
          <div class="p-d-flex p-jc-between p-ai-center">
            Yer
            <p-sortIcon field="displayStore"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="displayStore"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>

        <th pSortableColumn="stockStatusValue">
          <div class="p-d-flex p-jc-between p-ai-center">
            Stok Durumu
            <p-sortIcon field="stockStatusValue"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="stockStatusValue"
              display="menu"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th style="width: 9rem">İşlem</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr class="p-selectable-row">
        <td>
          {{ product.barcodeCode }}
        </td>
        <td>
          {{ product.name }}
        </td>
        <td
          [appTextColor]="product.isUnitExist === tableService.errorMessage ? 'warning' : ''"
        >
          {{ product.isUnitExist }}
        </td>
        <td>
          {{ product.calculatedItem }} Boy
        </td>

        <td>
          {{ product.kgPerUnit?.toFixed(2) }}
        </td>
        <td [appTextColor]="product.totalKg === tableService.errorMessage ? 'warning' : ''">
          {{ product.totalKg }}
        </td>
        <td
          [appTextColor]="
            product.displayStore === tableService.errorMessage ? 'warning' : ''
          "
        >
          {{ product.displayStore }}
        </td>

        <td>
          <span [class]="'product-badge status-' + product.stockStatusCss">{{
            product.stockStatusValue
          }}</span>
        </td>

        <td>
          <button
            pButton
            [disabled]="product.stockStatusValue === 'Stokta Yok!' && sharedService.isStockLocked"
            (click)="addToCart(product)"
            label="Sepete Ekle"
          ></button>
        </td>
      </tr>

    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">Ürün bulunamadı..</td>
      </tr>
    </ng-template>
  </p-table>
  <p-toast></p-toast>
</div>
<app-product-item
  *ngIf="sharedService.cartPopUp"
  [product]="selectedProduct"
></app-product-item>
