<h2>Toplu Taşıma</h2>
<p-divider></p-divider>
<p-card>
  <div class="container">
    <h4>Lütfen taşınmasını istediğiniz dükkan ve paleti seçiniz.</h4>
    <div class="p-grid p-align-center">
      <div class="p-fluid p-my-4 p-input-filled p-lg-5">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Dükkan Seçiniz"
            [showTransitionOptions]="'100ms'"
            scrollHeight="150px"
            [hideTransitionOptions]="'100ms'"
            required
            [virtualScroll]="true"
            itemSize="30"
            [options]="stores"
            [filter]="true"
            [(ngModel)]="selectedStore"
            optionLabel="name"
            emptyFilterMessage="Dükkan Bulunamadı!"
          ></p-dropdown>
        </span>
      </div>
      <div class="p-fluid p-my-4 p-input-filled p-lg-5">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Raf Seçiniz"
            [showTransitionOptions]="'100ms'"
            scrollHeight="150px"
            [hideTransitionOptions]="'100ms'"
            required
            [virtualScroll]="true"
            itemSize="30"
            [options]="selectedStore?.pallets"
            [filter]="true"
            [(ngModel)]="selectedPallet"
            optionLabel="name"
            emptyFilterMessage="Palet Bulunamadı!"
          ></p-dropdown>
        </span>
      </div>
      <div class="p-fluid p-my-4 p-lg-2">
        <p-button

          (click)="getList()"
          styleClass="p-button-warning"
          type="button"
          class="p-m-2"
          label="Listele"
        ></p-button>
      </div>
    </div>
    <div *ngIf="list" class="list p-m-auto">
      <h4>Taşınacak Malzemeler</h4>
      <div class="header p-grid">
        <div class="p-md-4">
          <h5>Malzeme Adı</h5>
        </div>
        <div class="p-md-4">
          <h5>Barkod Numarası</h5>
        </div>
        <div class="p-md-4">
          <h5>Palleteki Adeti</h5>
        </div>
      </div>
      <div *ngFor="let i of list" class="content p-grid">
        <div class="p-md-4">
          <p>{{i.name}}</p>
        </div>
        <div class="p-md-4">
          <p>{{i.barcodeCode}}</p>
        </div>
        <div class="p-md-4">
          <p>{{findAmountInPallet(i.pallets)[0]?.amountUnit}}</p>
        </div>
      </div>
      </div>
    <div *ngIf="list" class="new-store">
      <h4>Lütfen taşınacak dükkan ve paleti seçiniz.</h4>
      <div class="p-grid p-align-center">
        <div class="p-fluid p-my-4 p-input-filled p-lg-5">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Dükkan Seçiniz"
            [showTransitionOptions]="'100ms'"
            scrollHeight="150px"
            [hideTransitionOptions]="'100ms'"
            required
            [virtualScroll]="true"
            itemSize="30"
            [options]="stores"
            [filter]="true"
            [(ngModel)]="movedStore"
            optionLabel="name"
            emptyFilterMessage="Dükkan Bulunamadı!"
          ></p-dropdown>
        </span>
        </div>
        <div class="p-fluid p-my-4 p-input-filled p-lg-5">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Raf Seçiniz"
            [showTransitionOptions]="'100ms'"
            scrollHeight="150px"
            [hideTransitionOptions]="'100ms'"
            required
            [virtualScroll]="true"
            itemSize="30"
            [options]="movedStore?.pallets"
            [filter]="true"
            [(ngModel)]="movedPallet"
            optionLabel="name"
            emptyFilterMessage="Palet Bulunamadı!"
          ></p-dropdown>
        </span>
        </div>
        <div class="p-fluid p-my-4 p-lg-2">
          <p-button
            (click)="movedItems()"
            styleClass="p-button-success"
            type="submit"
            [disabled]="list.length===0"
            class="p-m-2"
            label="Taşı"
          ></p-button>
        </div>
      </div>
    </div>

    </div>
</p-card>

<p-toast></p-toast>
