<p-dialog
  header="Kur Ayarları"
  position="top"
  [(visible)]="sharedService.settingsPopUp"
  [baseZIndex]="1"
>
  <form [formGroup]="settingForm" class="content" (ngSubmit)="handleUpdate()">
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          step=".01"
          required
          formControlName="dollarRate"
          id="dolarRate"
          type="number"
          pInputText
        />
        <label for="dolarRate">Dolar Kuru</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          step=".01"
          required
          formControlName="euroRate"
          id="euroRate"
          type="number"
          pInputText
        />
        <label for="euroRate">Euro Kuru</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          step=".01"
          required
          formControlName="profitRate"
          id="profitRate"
          type="number"
          pInputText
        />
        <label for="profitRate">Kar Marjı</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <p-dropdown
          required
          [options]="KDVRATES"
          formControlName="kdvRate"
          id="kdvRate"
          optionValue="kdv"
          optionLabel="name"
        ></p-dropdown>
        <label for="kdvRate">KDV Oranı</label>
      </span>
    </div>

    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-secondary"
        (click)="handleClear()"
        class="p-m-2 p-button-secondary"
        label="Temizle"
      ></p-button>
      <p-button
        styleClass="p-button-warning"
        (click)="sharedService.toggleSettingsPopUp()"
        class="p-m-2"
        label="İptal"
      ></p-button>
      <p-button
        styleClass="p-button-success"
        [disabled]="settingForm.invalid"
        type="submit"
        class="p-m-2"
        label="Kaydet"
      ></p-button>
    </div>
  </form>
  <div
    *ngIf="settingForm.get('profitRate').dirty"
    class="p-fluid p-my-4 p-input-filled"
  >
    <span class="p-float-label">
      <input [(ngModel)]="password" id="sifre" type="password" pInputText />
      <label for="sifre">Şifre</label>
    </span>
  </div>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog header="Kur Onayı" icon="pi pi-exclamation-triangle"></p-confirmDialog>
