<h2>Stok Geçmişi</h2>
<p-divider></p-divider>
<div class="export">
  <div>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-excel"
      (click)="exportExcel()"
      class="p-button-success p-mr-2"
      pTooltip="Excel Kaydet"
      tooltipPosition="bottom"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-bars"
      (click)="getAllData()"
      class="p-button-info p-mr-2"
      pTooltip="Tümünü Listele"
      tooltipPosition="bottom"
    ></button>
  </div>
  <div class="search-area">
    <input
      (keydown.enter)="search(text.value)"
      #text
      pInputText
      type="text"
      placeholder="Arama"
    />
    <button
      style="width: 50px"
      (click)="search(text.value)"
      pButton
      pRipple
      icon="pi pi-search"
    ></button>
    <button
      style="width: 50px"
      (click)="clear()"
      pButton
      pRipple
      icon="pi pi-times"
      class="p-button-help p-ml-1"
    ></button>
  </div>
</div>
<div *ngIf="!loading">
  <p-card
    *ngFor="let stock of searchList"
    [style]="{ 'margin-bottom': '1em', padding: '0 5px' }"
  >
    <div class="top p-grid p-justify-between">
      <p class="p-lg-2">İşlemi Yapan: {{ stock.user?.name }}</p>
      <p>
        Tarih:
        <strong>{{ stock.date | date: 'medium' }}</strong>
      </p>
    </div>
    <div class="header p-grid">
      <h4 class="p-lg-3">Malzeme İsmi</h4>
      <h4 class="p-lg-3">Barkod</h4>
      <h4 class="p-lg-2">Malzeme Tipi</h4>
      <h4 class="p-lg-1">Miktar</h4>
      <h4 class="p-lg-2">Palet</h4>
      <h4 class="p-lg-1">İşlem</h4>
    </div>
    <div class="content p-grid p-align-center">
      <p class="p-lg-3">{{ stock.product.name }}</p>
      <p class="p-lg-3">{{ stock.product.barcodeCode }}</p>
      <p class="p-lg-2">{{ stock.product.unitType.name }}</p>
      <p class="p-lg-1">
        {{ stock.addedStock.amountUnit }} {{ stock.product.unitType.symbol }}
      </p>
      <p class="p-lg-2">
        {{ stock.addedStock.storeName }}-{{ stock.addedStock.storeShelf }}
      </p>
      <button
        *ngIf="!stock.isCancel"
        (click)="returnItem(stock)"
        pButton
        class="p-button-danger"
        label="Girişi Sil"
      ></button>
      <p *ngIf="stock.isCancel" class="p-lg-1">
        <span class="p-badge p-badge-warning">Silinmiş</span>
      </p>
    </div>
  </p-card>

</div>
<p-toast></p-toast>
<p-confirmDialog
  header="Stok Silme Onayı"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<p-progressSpinner *ngIf="loading"
></p-progressSpinner>
