import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  counter = 10;
  constructor(protected router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.goHomePage();
    }, 10000);
    setInterval(() => this.counter-- , 1000);
  }

  goHomePage() {
    this.router.navigate(['/']).then();
  }
}
