<div class="login-card p-shadow-6 p-p-3">
  <h4 class="p-text-center">Stok Yönetim Ekranına Hoşgeldiniz</h4>
  <form #loginFrom="ngForm" class="card-inputs" (ngSubmit)="login(loginFrom)">
    <div class="input-container">
      <span class="p-float-label">
        <input required class="full-width" ngModel type="text" id="username" name="username" pInputText />
        <label for="username">Kullanıcı Adı</label>
      </span>
    </div>
    <div class="input-container">
      <span class="p-float-label">
        <input required minlength="6" ngModel class="full-width" (keyup.enter)="login(loginFrom)" name="password"
          id="password" type="password" pInputText />
        <label for="password">Şifre</label>
      </span>
    </div>
    <div class="card-buttons">
      <button (click)="clear(loginFrom)" pButton label="Temizle" class="p-button-warning"></button>

      <button [disabled]="loginFrom.invalid" type="submit" pButton label="Giriş yap" class="p-button-success"></button>
    </div>
  </form>
</div>
<p-toast></p-toast>