import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { MenubarModule } from 'primeng/menubar';
import { SharedModule } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { AccordionModule } from 'primeng/accordion';
import { ToggleButtonModule } from 'primeng/togglebutton';

const ngModules = [
  CommonModule,
  InputTextModule,
  ButtonModule,
  CheckboxModule,
  RadioButtonModule,
  RippleModule,
  TabViewModule,
  MenubarModule,
  SharedModule,
  PanelModule,
  CardModule,
  TableModule,
  ToastModule,
  DropdownModule,
  DialogModule,
  TooltipModule,
  DividerModule,
  InputNumberModule,
  ConfirmDialogModule,
  ProgressSpinnerModule,
  InputTextareaModule,
  InputMaskModule,
  AccordionModule,
  ToggleButtonModule,
];

@NgModule({
  declarations: [],
  imports: [...ngModules],
  exports: [...ngModules],
})
export class PrimeNgModule {}
