import { Component, OnInit } from '@angular/core';
import { StatesService } from '../../../services/states.service';
import { FirebaseService } from '../../../services/firebase.service';
import { MessageService } from 'primeng/api';
import {
  ProductModal,
  ProductPalletModal,
} from '../../../modals/product.modal';
import { Pallet, StoreModal } from '../../../modals/store.modal';

@Component({
  selector: 'app-move-product-item',
  templateUrl: './move-product-item.component.html',
  styleUrls: ['./move-product-item.component.scss'],
  providers: [MessageService],
})
export class MoveProductItemComponent implements OnInit {
  constructor(
    private state: StatesService,
    private fs: FirebaseService,
    private msg: MessageService
  ) {}

  products: ProductModal[];
  stores: StoreModal[];
  selectedProduct: ProductModal;
  selectedStore: StoreModal;
  selectedPalet: Pallet;

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData() {
    if (this.state.productsGlobal.length > 0) {
      this.products = this.state.productsGlobal;
    } else {
      this.fs.getAllProducts().subscribe((response) => {
        this.products = response;
        this.state.productsGlobal = response;
      });
    }
    if (this.state.storesGlobalStates.length > 0) {
      this.stores = this.state.storesGlobalStates;
    } else {
      this.fs.getStores().subscribe((response) => {
        this.stores = response;
        this.state.storesGlobalStates = response;
      });
    }
  }
  moveProduct(palet: ProductPalletModal, amount: number) {
    // Taşınacak yer seçildi mi?

    if(!this.selectedStore || !this.selectedPalet){
      this.msg.add({
        severity:'info',
        summary:'Lütfen Seçim Yapnız.',
        detail:'Taşınacak dükkan ve raf seçimi yapmalısınız.'
      })
      return;
    }
    const newPallet: ProductPalletModal = {
      id: this.selectedPalet.id,
      storeShelf: this.selectedPalet.name,
      storeName: this.selectedStore.name,
      amountUnit: amount,
    };

    // palet daha önceden aynı üründe varsa, yeni palet eklemeden mevcut olana ekliyor.
    let isExistBefore = false;
    isExistBefore = this.updateCurrentPallet(newPallet, isExistBefore);

    // Eğer palet daha önceden yoksa, yeni olarak paleti ekliyor.
    this.updateNewPallet(isExistBefore, newPallet);

    // eğer taşınacak miktar, palatte bulunan toplam miktardan azsa, taşınan kadarı mevcut paletten çıkar
    this.reCalculatePallet(amount, palet);

    // Database güncellenir.
    this.updateProduct(palet,newPallet)

  }


  private reCalculatePallet(amount: number, palet: ProductPalletModal) {
    if (amount < palet.amountUnit) {
      this.selectedProduct.pallets.forEach((p) => {
        if (p.id === palet.id) {
          p.amountUnit -= amount;
        }
      });
    } else {
      this.selectedProduct.pallets = this.selectedProduct.pallets.filter(
        (p) => p.id !== palet.id
      );
    }
  }

  private updateNewPallet(isExistBefore: boolean, newPallet: ProductPalletModal) {
    if (!isExistBefore) {
      this.selectedProduct.pallets.push(newPallet);
    }
  }

  private updateCurrentPallet(newPallet: ProductPalletModal, isExistBefore: boolean) {
    this.selectedProduct.pallets = this.selectedProduct.pallets.map((p) => {
      if (p.id === newPallet.id) {
        isExistBefore = true;
        return {
          ...p,
          amountUnit: p.amountUnit + newPallet.amountUnit
        };
      }
      return p;
    });
    return isExistBefore;
  }

  updateProduct(palet:ProductPalletModal, newPallet:ProductPalletModal){
    this.fs
      .updateProduct(this.selectedProduct)
      .then(() => {
        this.msg.add({
          severity: 'success',
          summary: 'Ürün taşındı.',
          detail: `${palet.storeName} - ${palet.storeShelf}'den, ${newPallet.storeName}-${newPallet.storeShelf}'e ürün taşındı.`,
        });

        this.selectedStore = null;
      })
      .catch((err) => {
        this.msg.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err,
        });
      });
  }
}
