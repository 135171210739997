<p-dialog
  header="Teklif Önizleme"
  position="top"
  [(visible)]="sharedService.offerScreen"
  [closable]="false"
>
  <div id="print-section" class="order-screen">
    <div>
      <div class="header-container">
        <img
          height="40px"
          width="130px"
          src="../../../assets/images/logo.jpg"
          alt="logo"
        />

        <p class="order-text">
          <strong>Teklif Numarası: </strong
          ><span class="orderNo">{{ siparisNo }}</span>
        </p>
        <p>{{ getToday() }}</p>
      </div>
      <div class="top-area">
        <div *ngIf="cartService.costumer" class="musteri">
          <p>Sayın {{ cartService.costumer.officer }}</p>
          <p>{{ cartService.costumer.name }}</p>
          <p>{{ cartService.costumer.email }}</p>
          <p *ngIf="cartService.costumer.homePhone">
            {{ cartService.costumer.homePhone }}
            <span class="m-left" *ngIf="cartService.costumer.mobilePhone">{{
              cartService.costumer.mobilePhone
            }}</span>
          </p>
        </div>
        <div class="company-info">
          <h3>{{ companyInfo.name }}</h3>
          <p class="text-align-right">{{ companyInfo.address }}</p>
          <p class="text-align-right">{{ companyInfo.city }}</p>
          <p class="text-align-right">
            Tel: {{ companyInfo.phone }}
            <span class="m-left">Cep: {{ companyInfo.selfPhone }}</span>
          </p>
          <p class="text-align-right">{{ companyInfo.mail }}</p>
        </div>
      </div>
      <table>
        <tr>
          <th>Sıra No</th>
          <th>Barkod</th>
          <th>Malzeme</th>
          <th>Miktar</th>
          <th>Birim</th>
          <th>Fiyat/KG</th>
          <th>KG</th>
          <th>Toplam Fiyatı</th>
        </tr>
        <tr *ngFor="let item of printList; let i = index" [attr.data-index]="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.item.barcodeCode }}</td>
          <td>{{ item.item.name }}</td>
          <td class="quantity">{{calculateQuantity(item)}}</td>
          <td>
            {{
            item.item.unitType.name
            }}
          </td>
          <td>
            {{
              !item.item.unitType.name.includes('Adet')
                ? (
                    item.item.cost *
                    (1 + sharedService.profitRate / 100) * sharedService.dollarRate
                  ).toFixed(1) +
                  ' ₺'
                : '-'
            }}
          </td>
          <td>
            {{
              !item.item.unitType.name.includes('Adet')
                ? item.item.unitType.name === 'Plaka'
                  ? (item.item.kgPerUnit * item.quantity).toFixed(2)
                  : ((item.item.kgPerUnit * item.quantity) / 1000).toFixed(2)
                : '-'
            }}
          </td>
          <td>{{ calculateItem(item).toFixed(2) }} ₺</td>
        </tr>
        <!--        <ng-template *ngFor="let item of printList; let i = index">
          <tr *ngIf="checkIsAdet(item)">
            <td>{{ i + 1 }}</td>
            <td>{{ item.item.barcodeCode }}</td>
            <td>{{ item.item.name }}</td>
            <td class="quantity"></td>
            <td class="quantity"></td>
            <td></td>
            <td></td>
            <td>{{ calculateItem(item).toFixed(1) }} ₺</td>
          </tr>
          <tr *ngIf="!checkIsAdet(item)">
            <td>{{ i + 1 }}</td>
            <td>{{ item.item.barcodeCode }}</td>
            <td>{{ item.item.name }}</td>
            <td class="quantity">{{ item.quantity }} {{item.item.unitType.symbol}}</td>
            <td class="quantity">{{ item.item.unitType.name }}</td>
            <td>
              {{
              (item.item.cost * (1+sharedService.profitRate/100)).toFixed(1)
              }}
              {{ item.item.costCurrency.abbs }}
            </td>
            <td>{{ item.item.unitType.name === 'Plaka' ? (item.item.kgPerUnit*item.quantity).toFixed(1) : (item.item.kgPerUnit * item.quantity/1000).toFixed(1) }}</td>
            <td>{{ calculateItem(item).toFixed(1) }} ₺</td>
          </tr>
        </ng-template>-->
      </table>
      <div class="price-box">
        <div class="price-container">
          <div class="price-item">
            <p class="price-tag">Toplam:</p>
            <p class="price-text">
              {{ cartService.calculateBasePrice().toFixed(2) }} ₺
            </p>
          </div>
          <div class="price-item">
            <p class="price-tag">Vergiler:</p>
            <p class="kdv-text">
              {{ cartService.calculateKDV().toFixed(2) }} ₺
            </p>
          </div>
          <div class="price-item">
            <p class="price-tag total-text p-mr-2">Genel Toplam:</p>
            <p class="total-text">
              {{ cartService.calculateWithKdv().toFixed(2) }} ₺
            </p>
          </div>
        </div>
      </div>
    <div *ngIf='state.user' class='note-text'>
      İşlemi Yapan: <span>{{state.user.name}}</span>
    </div>
      <div *ngIf="cartService.cartNote" class="note-container">
        <p>
          <span class="p-text-bold bold">Not: </span>{{ cartService.cartNote }}
        </p>
      </div>

      <div class="iban-container p-d-none">
        <h2>Hatırlatmalar</h2>
        <div>
          <p><strong>OPSİYON: </strong>1 İŞ GÜNÜ</p>
          <p>
            <strong>ÖDEME ŞEKLİ: </strong>PEŞİN ( SİPARİŞ ESNASINDA HAVALE )
          </p>
          <p><strong>TESLİMAT: </strong>İKİTELLİ DEPOMUZDAN ARACINIZ ÜZERİ</p>
        </div>
        <hr />
        <div class="iban-content">
          <div class="iban-item">
            <div class="iban-4">Garanti Bankası TL:</div>
            <div class="iban-8">
              {{ companyInfo.ibanGarantiTl }}
            </div>
          </div>
          <div class="iban-item">
            <div class="iban-4">Garanti Bankası Dolar:</div>
            <div class="iban-8">
              {{ companyInfo.ibanYapiKrediUSD }}
            </div>
          </div>
          <div class="iban-item">
            <div class="iban-4">Yapı Kredi TL:</div>
            <div class="iban-8">
              {{ companyInfo.ibanYapiKrediTL }}
            </div>
          </div>
          <div class="iban-item">
            <div class="iban-4">Yapı Kredi Dolar:</div>
            <div class="iban-8">
              {{ companyInfo.ibanYapiKrediUSD }}
            </div>
          </div>
          <div class="iban-item">
            <div class="iban-4">Türkiye İş Bankası TL:</div>
            <div class="iban-8">
              {{ companyInfo.ibanTurkiyeIsBankasiTL }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="print-button">
    <button
      [printTitle]="cartService.costumer?.name + '-' + siparisNo"
      [printStyle]="printStyle"
      (click)="print()"
      printSectionId="print-section"
      ngxPrint
      pButton
      label="Yazdır"
    ></button>
    <button
      (click)="closePage()"
      pButton
      class="p-button-danger"
      label="İptal"
    ></button>
  </div>
</p-dialog>
<p-toast></p-toast>
