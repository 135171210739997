import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FirebaseService } from '../../../services/firebase.service';
import { StatesService } from '../../../services/states.service';
import { ProductModal } from '../../../modals/product.modal';
import { CalculateService } from '../../../services/calculate.service';

@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.scss'],
  providers: [MessageService],
})
export class NewItemComponent implements OnInit {
  kgPerUnit: number;
  value1: number;
  value2: number;
  value3: number;

  newProductFrom = new FormGroup({
    unitType: new FormControl(this.sharedService.units[0]),
    costCurrency: new FormControl({ name: 'Dollar', abbs: '$' }),
    name: new FormControl(''),
    cost: new FormControl(''),
    minimumAmount: new FormControl(''),
    barcodeCode: new FormControl(''),
    value1: new FormControl(null),
    value2: new FormControl(null),
    value3: new FormControl(null),
    kgPerUnit: new FormControl(null),
    perItem: new FormControl(null),
  });

  constructor(
    public sharedService: SharedService,
    private messageService: MessageService,
    private firebaseService: FirebaseService,
    private stateService: StatesService,
    private calculate: CalculateService
  ) {}

  ngOnInit(): void {}

  checkItemIsDuplicated(item: ProductModal) {
    return this.stateService.productsGlobal.some(
      (p) =>
        p.barcodeCode.trim().toLocaleLowerCase() ===
        item.barcodeCode.trim().toLocaleLowerCase()
    );
  }
  handleAddItem() {
    this.calculate.hesapla(this.newProductFrom);
    const payload = { ...this.newProductFrom.value };
    if (!this.checkItemIsDuplicated(payload)) {
      this.firebaseService
        .addProduct(payload)
        .then(() => {
          this.handleClear();
          this.closePopup();
        })
        .catch((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Hata!',
            detail: `${error}`,
          });
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Barkod Numarası Hatası!',
        detail: `Bu barkod numarası daha önce girişmiş, lütfen kontrol ediniz.`,
      });
    }
  }

  handleClear() {
    this.newProductFrom.reset({
      costCurrency: { name: 'Dolar', symbol: '$' },
      unitType: {
        name: 'Plaka',
        symbol: 'PL',
        unit: 'Plaka',
        carpan: 8,
        input: 3,
      },
    });
  }

  closePopup() {
    this.sharedService.toggleNewItem();
  }

  hesapla(newProductFrom: FormGroup) {
    this.calculate.hesapla(newProductFrom);
  }
}
