import {Directive, ElementRef, HostBinding, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTextColor]'
})
export class TextColorDirective implements OnInit {
  @HostBinding('style.backgroundColor') backgroundColor: string;
  @HostBinding('style.cursor') cursor: any;
  @Input('appTextColor') textColor: string;
  color: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

/*  @HostListener('mouseenter') mouseover(eventData: Event) {
    this.backgroundColor = 'orange';
    this.cursor = 'pointer';
  }

  @HostListener('mouseleave') mouseleave(eventData: Event) {
    this.backgroundColor = null;
    this.cursor = null;
  }*/

  ngOnInit() {
    // this.renderer.setStyle(this.elementRef.nativeElement, 'color', '#ccc');
    switch (this.textColor) {
      case 'primary':
        this.color = '#4C5EBA';
        break;
      case 'secondary':
        this.color = '#ccc';
        break;
      case 'warning':
        this.color = '#ca0303';
        break;
      default:
        this.color = 'black';
        break;
    }
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', this.color);

  }

}
