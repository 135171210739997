<p-dialog
  header="Ürün Ekle"
  position="top"
  [(visible)]="sharedService.newItemPopUp"
  styleClass="pop-up-container"
>
  <form
    [formGroup]="newProductFrom"
    class="content"
    (ngSubmit)="handleAddItem()"
  >
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="barcodeCode"
          id="barcodeCode"
          type="text"
          pInputText
        />
        <label for="barcodeCode">Barkod Kodu</label>
      </span>
    </div>

    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="name"
          id="name"
          type="text"
          pInputText
        />
        <label for="name">Malzeme Adı</label>
      </span>
    </div>
    <div class="p-grid p-my-4">
      <div class="p-fluid p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <p-dropdown
            required
            [options]="sharedService.units"
            formControlName="unitType"
            optionLabel="name"
            id="unit"
          ></p-dropdown>
          <label for="unit">Ürün Tipi</label>
        </span>
      </div>
      <div class="p-fluid p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Kuru Seçiniz"
            required
            [options]="sharedService.currencies"
            formControlName="costCurrency"
            optionLabel="name"
            id="costCurrency"
          ></p-dropdown>
          <label for="costCurrency">Kur</label>
        </span>
      </div>
    </div>
    <div class="p-grid">
      <ng-template
        [ngIf]="newProductFrom.get('unitType').value.input === 1"
        class="p-grid p-my-4"
      >
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-12">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first3"
              type="number"
              pInputText
            />
            <label for="first">{{
              newProductFrom.get('unitType').value.value1
            }}</label>
          </span>
        </div>
      </ng-template>
      <ng-template
        [ngIf]="newProductFrom.get('unitType').value.input === 2"
        class="p-grid p-my-4"
      >
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first"
              type="number"
              pInputText
            />
            <label for="first">{{
              newProductFrom.get('unitType').value.value1
            }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
          <span class="p-float-label">
            <input
              formControlName="value2"
              id="second"
              type="number"
              pInputText
            />
            <label for="second">{{
              newProductFrom.get('unitType').value.value2
            }}</label>
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="newProductFrom.get('unitType').value.input === 3">
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first1"
              type="number"
              pInputText
            />
            <label for="first1">{{
              newProductFrom.get('unitType').value.value1
            }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value2"
              id="second1"
              type="number"
              pInputText
            />
            <label for="second1">{{
              newProductFrom.get('unitType').value.value2
            }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value3"
              id="tick"
              type="number"
              pInputText
            />
            <label for="tick">{{
              newProductFrom.get('unitType').value.value3
            }}</label>
          </span>
        </div>
      </ng-template>
    </div>
    <div class="p-lg-12 p-text-center">
      <p-button
        styleClass="p-button-secondary"
        (click)="hesapla(newProductFrom)"
        class="p-button-secondary"
        label="Hesapla"
      ></p-button>
    </div>
    <div *ngIf="newProductFrom.get('unitType').value.name !== 'Adet' && newProductFrom.get('unitType').value.name !== 'Plaka'" class="p-grid p-my-4 p-align-center">
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-12">
        <span class="p-float-label">
          <input
            formControlName="perItem"
            id="perItem"
            type="number"
            pInputText
          />
          <label for="perItem">Birim başına uzunluk(Milimetre)</label>
        </span>
      </div>
    </div>
    <div class="p-grid p-my-4">
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <input
            required
            id="unitType"
            type="number"
            formControlName="minimumAmount"
            pInputText
          />
          <label for="unitType">Uyarı Miktarı</label>
          <span class="p-inputgroup-addon">{{
            newProductFrom.get('unitType').value?.symbol
          }}</span>
        </span>
      </div>
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <input
            disabled
            id="kg"
            type="number"
            formControlName="kgPerUnit"
            pInputText
          />
          <label for="kg"
            >1000 {{ newProductFrom.get('unitType').value.unit }} / KG
          </label>
        </span>
      </div>
    </div>

    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-secondary"
        (click)="handleClear()"
        class="p-m-2 p-button-secondary"
        label="Temizle"
      ></p-button>
      <p-button
        styleClass="p-button-warning"
        (click)="sharedService.toggleNewItem()"
        class="p-m-2"
        label="İptal"
      >
      </p-button>
      <p-button
        styleClass="p-button-success"
        [disabled]="newProductFrom.invalid"
        type="submit"
        class="p-m-2"
        label="Kaydet"
      ></p-button>
    </div>
  </form>
</p-dialog>

<p-toast></p-toast>
