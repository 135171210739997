import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
import { MessageService } from 'primeng/api';
import { FirebaseService } from '../../../services/firebase.service';

@Component({
  selector: 'app-new-costumer',
  templateUrl: './new-costumer.component.html',
  styleUrls: ['./new-costumer.component.scss'],
  providers: [MessageService],
})
export class NewCostumerComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    private messageService: MessageService,
    private firebaseService: FirebaseService
  ) {}
  newCostumerForm = new FormGroup({
    name: new FormControl(''),
    officer: new FormControl(''),
    email: new FormControl(''),
    address: new FormControl(''),
    mobilePhone: new FormControl(''),
    homePhone: new FormControl(''),
  });

  ngOnInit(): void {}

  handleAddCostumer(): void {
    this.firebaseService
      .addCostumer(this.newCostumerForm.value)
      .then(() => {
        this.handleClear();
        this.sharedService.toggleNewCostumer();
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Hata.',
          detail: `${error}`,
        });
      });
  }

  handleClear(): void {
    this.newCostumerForm.reset();
  }
}
