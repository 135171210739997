import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreModal } from '../../../modals/store.modal';
import { MessageService } from 'primeng/api';
import { FirebaseService } from '../../../services/firebase.service';
import { StatesService } from '../../../services/states.service';

@Component({
  selector: 'app-new-store',
  templateUrl: './new-store.component.html',
  styleUrls: ['./new-store.component.scss'],
  providers: [MessageService],
})
export class NewStoreComponent implements OnInit {
  id: string;
  selectedStore = null;
  isStoreEditing = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private message: MessageService,
    private firebaseService: FirebaseService,
    public state: StatesService
  ) {}

  ngOnInit(): void {
    this.state.storesGlobalStates.sort((a, b) => a.name.localeCompare(b.name));
  }

  resetSelectedStore() {
    this.selectedStore = null;
    this.isStoreEditing = true;
  }

  editToggle(): void {
    this.isStoreEditing = !this.isStoreEditing;
  }

  addStore(): void {
    this.isStoreEditing = false;
    this.selectedStore = new StoreModal('', '', null);
  }

  deleteStore() {
    let checkHasProduct = false;
    this.selectedStore.pallets.forEach((palet) => {
      if (
        this.state.productsGlobal.some((s) =>
          s.pallets.some((p) => p.id === palet.id)
        )
      ) {
        checkHasProduct = true;
      }
    });

    if (checkHasProduct) {
      this.message.add({
        severity: 'error',
        summary: 'Hata!',
        detail: 'Silmek istediğiniz dükkanda ürün var.',
      });
      return;
    } else {
      this.firebaseService
        .removeStore(this.selectedStore)
        .then(() => {
          this.message.add({
            severity: 'success',
            summary: 'Silindi!',
            detail: `${this.selectedStore.name} silindi!`,
          });
          this.resetSelectedStore();
        })
        .catch((err) => {
          this.message.add({
            severity: 'error',
            summary: 'Hata!',
            detail: err,
          });
        });
    }
  }

  handleAddStore(): void {
    if (this.selectedStore.name.length < 1) {
      return this.message.add({
        severity: 'error',
        summary: 'Hata!',
        detail: `Lütfen Dükkan Adını Giriniz.`,
      });
    }

    if (this.isStoreEditing) {
      this.saveEditStore();
    } else {
      this.saveNewStore();
    }
  }

  private saveEditStore() {
    this.firebaseService
      .updateStore({
        ...this.selectedStore,
      })
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Güncellendi!',
          detail: `${this.selectedStore.name} güncellendi.`,
        });
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err,
        });
      });
  }
  private saveNewStore() {
    const checkisStoreExist = this.state.storesGlobalStates.some(
      (s) =>
        s.name.trim().toLocaleLowerCase() ===
        this.selectedStore.name.trim().toLocaleLowerCase()
    );
    if (checkisStoreExist) {
      return this.message.add({
        severity: 'error',
        summary: 'İsim Hatası!',
        detail: `Bu isimde dükkan mevcut. Başka bir isim giriniz.`,
      });
    }
    this.firebaseService
      .addStore({
        ...this.selectedStore,
        pallets: [],
      })
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Başarıyla Eklendi!',
          detail: `${this.selectedStore.name} başarıyla eklendi.`,
        });
        this.isStoreEditing = false;
        this.resetSelectedStore();
      })
      .catch((error) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: `${error}`,
        });
      });
  }
}
