import { Component, OnInit } from '@angular/core';
import { ProductModal } from 'src/app/modals/product.modal';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'app-stock-status',
  templateUrl: './stock-status.component.html',
  styleUrls: ['./stock-status.component.scss']
})
export class StockStatusComponent implements OnInit {

  productList: ProductModal[];
  loading = true;
  constructor(private fs : FirebaseService, public tableService:TableService, private auth:AuthService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.fs.getAllProducts().subscribe(response => {
      this.tableService.arrayData = this.tableService.convertTable(response);
      this.loading = false;
    })
  }

  logOut() {
    this.auth.signOut();
  }

}
