<p-dialog
  header="Müşteri Ekle"
  position="top"
  [(visible)]="sharedService.newCostumerPopUp"
>
  <form
    [formGroup]="newCostumerForm"
    class="content"
    (ngSubmit)="handleAddCostumer()"
  >
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="name"
          id="name"
          type="text"
          pInputText
        />
        <label for="name">Firma Adı</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input

          formControlName="officer"
          id="officer"
          type="text"
          pInputText
        />
        <label for="officer">Firma Yetkilisi</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input

          formControlName="email"
          id="email"
          type="email"
          pInputText
        />
        <label for="email">E-Mail Adresi</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
         <p-inputMask mask="(999) 999-9999"
                      formControlName="mobilePhone"
                      id="mobilePhone"
         ></p-inputMask>
        <label for="mobilePhone">Telefon Numarası 1</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
                <p-inputMask mask="(999) 999-9999"
                             formControlName="homePhone"
                             id="homePhone"
                             ></p-inputMask>
        <label for="homePhone">Telefon Numarası 2</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <textarea
          formControlName="address"
          id="address"
          type="text"
          pInputTextarea
          rows="3"
        ></textarea>
        <label for="address">Adres</label>
      </span>
    </div>
    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-secondary"
        (click)="handleClear()"
        class="p-m-2 p-button-secondary"
        label="Temizle"
      ></p-button>
      <p-button
        styleClass="p-button-warning"
        (click)="sharedService.toggleNewCostumer()"
        class="p-m-2"
        label="İptal"
      ></p-button>
      <p-button
        styleClass="p-button-success"
        [disabled]="newCostumerForm.invalid"
        type="submit"
        class="p-m-2"
        label="Kaydet"
      ></p-button>
    </div>
  </form>
</p-dialog>
<p-toast></p-toast>
