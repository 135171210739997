import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
import { ProductModal } from '../../../modals/product.modal';
import { FirebaseService } from '../../../services/firebase.service';
import { StatesService } from '../../../services/states.service';
import { MessageService } from 'primeng/api';
import { CalculateService } from '../../../services/calculate.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  providers: [MessageService],
})

export class EditProductComponent implements OnInit {
  selectedProduct: ProductModal;
  newProductFrom: FormGroup;
  oldCost: number;
  products: ProductModal[];

  constructor(
    public shared: SharedService,
    private fs: FirebaseService,
    private state: StatesService,
    private message: MessageService,
    private calculate: CalculateService
  ) {}

  ngOnInit(): void {
    this.products = this.state.productsGlobal;
  }

  getProduct() {
    this.newProductFrom = new FormGroup({
      unitType: new FormControl(this.selectedProduct?.unitType),
      costCurrency: new FormControl(this.selectedProduct?.costCurrency),
      name: new FormControl(this.selectedProduct?.name),
      cost: new FormControl(this.selectedProduct?.cost),
      minimumAmount: new FormControl(this.selectedProduct?.minimumAmount),
      barcodeCode: new FormControl(this.selectedProduct?.barcodeCode),
      value1: new FormControl(this.selectedProduct?.value1),
      value2: new FormControl(this.selectedProduct?.value2),
      value3: new FormControl(this.selectedProduct?.value3),
      kgPerUnit: new FormControl({
        value: this.selectedProduct?.kgPerUnit,
        disabled: true,
      }),
      perItem: new FormControl(this.selectedProduct?.perItem),
    });

    this.oldCost = this.selectedProduct?.cost;
  }

  updateItem() {
    const payload: ProductModal = {
      // tslint:disable-next-line:max-line-length
      oldCost:
        this.selectedProduct.cost !== this.newProductFrom.get('cost')?.value
          ? this.oldCost
          : this.selectedProduct?.oldCost,
      ...this.selectedProduct,
      ...this.newProductFrom.value,
    };

    this.fs
      .updateProduct({...payload})
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'Güncellendi!',
          detail: `${payload.name} malzemesi güncellendi.`,
        });
        this.selectedProduct = null;
        this.products = this.state.productsGlobal;
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err,
        });

      });
  }

  hesapla() {
    this.calculate.hesapla(this.newProductFrom);
  }
}
