import { EventEmitter, Injectable } from '@angular/core';
import { ProductPalletModal } from '../modals/product.modal';
import { SalesItemModal } from '../modals/sales-item.modal';
import { FirebaseService } from './firebase.service';
import { SharedService } from './shared.service';
import { CostumerModal } from '../modals/costumer.modal';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  cartList: Array<SalesItemModal> = [];
  cartUpdated = new EventEmitter<SalesItemModal[]>();
  // tslint:disable-next-line:variable-name
  private _cartNote: string;
  // tslint:disable-next-line:variable-name
  private _costumer: CostumerModal;
  get costumer(): CostumerModal {
    return this._costumer;
  }

  set costumer(value: CostumerModal) {
    this._costumer = value;
  }

  constructor(
    private sharedService: SharedService,
    private firebaseService: FirebaseService
  ) {}

  getCartItems = () => this.cartList;

  setCartItems = (value, costumer) => {
    this.cartList = value;
    this.costumer = costumer;
    this.cartUpdated.emit(this.cartList.slice());
  };

  makeEmptyCart = () => {
    this.cartList = [];
    this.costumer = null;
    this.cartNote = null;
    this.sharedService.isStockLocked = true;
    this.cartUpdated.emit(this.cartList.slice());
  };

  get cartNote(): string {
    return this._cartNote;
  }

  set cartNote(value: string) {
    this._cartNote = value;
  }

  removeToCart = (item: SalesItemModal) => {
    this.cartList = this.cartList.filter((i) => i.id !== item.id);
    if (this.cartList.length === 0) {
      this.makeEmptyCart();
    }
    this.cartUpdated.emit(this.cartList.slice());
  };

  calculateBasePrice = (): number => {
    // Calculate each item in the cart to find total price without tax
    let total = 0;
    this.cartList.forEach((item) => {
      total += this.calculateCartItem(
        item.salesPrice,
        item.item.costCurrency.name
      );
    });
    return total;
  };

  calculateKDV = (): number => {
    // Calculate Tax of cart

    return (+this.calculateBasePrice() * this.sharedService.kdvRate) / 100;
  };

  updateCartItem(item: SalesItemModal) {
    this.cartList = this.cartList.map((i) => {
      if (i.id === item.id) {
        return item;
      }
      return i;
    });
    this.cartUpdated.emit(this.cartList.slice());
  }

  calculateWithKdv = (): number => {
    // Calculate final price of chart with tax

    return +this.calculateBasePrice() + this.calculateKDV();
  };

  calculateSalesPrice = (item: SalesItemModal) => {
    // Calculate sales price of cart item with cost, quantity

    if (
      item.item.unitType.name === 'Plaka' ||
      item.item.unitType.name === 'Adet'
    ) {
      return item.item.kgPerUnit * item.quantity * item.item.cost;
    }
    return ((item.item.kgPerUnit * item.quantity) / 1000) * item.item.cost;
  };

  calculateCartItem = (salesPrice: number, currency: string): number => {
    // Calculate each item of cart to turkish lira

    if (currency.toLowerCase() === 'dollar') {
      return (
        salesPrice *
        this.sharedService.dollarRate *
        this.convertRateToPercent(this.sharedService.profitRate)
      );
    } else if (currency.toLowerCase() === 'euro') {
      return (
        salesPrice *
        this.sharedService.euroRate *
        this.convertRateToPercent(this.sharedService.profitRate)
      );
    } else if (currency.toLowerCase() === 'türk lirası') {
      return salesPrice;
    } else {
      throw Error('Kur tipi bulunamadı!');
    }
  };

  convertRateToPercent = (rate: number): number => {
    return rate / 100 + 1;
  };

  findTotalQuantiy = (item: SalesItemModal) => {
    let result = 0;
    item.locations.forEach((l) => (result += l.quantity));
    return result;
  };

  addToCart = (item: SalesItemModal) => {
    let payload: SalesItemModal;
    let pallet: { pallet: ProductPalletModal; quantity: number };
    this.cartList.forEach((p) => {
      const firstLength = p.locations.length;
      if (p.item.barcodeCode === item.item.barcodeCode) {
        p.quantity += this.findTotalQuantiy(item);
        p.salesPrice += this.calculateSalesPrice(item);
        p.salesPriceTL += this.calculateCartItem(
          this.calculateSalesPrice(item),
          item.item.costCurrency.name
        );
        p.maxStockAmount =
          p.locations.length <= firstLength
            ? p.maxStockAmount
            : p.maxStockAmount + item.maxStockAmount;
        payload = p;

        // Checking if same location (pallets) added before. If yes update quantity.
        p.locations.forEach((l) => {
          if (l.pallet.id === item.locations[0].pallet.id) {
            l.quantity += item.locations[0].quantity;
            pallet = l;
          }
        });
        if (!pallet) {
          p.locations.push(item.locations[0]);
        }
      }
    });

    // if item add to cart first time
    if (!payload) {
      payload = {
        ...item,
        salesPrice: this.calculateSalesPrice(item),
        salesPriceTL: this.calculateCartItem(
          this.calculateSalesPrice(item),
          item.item.costCurrency.name
        ),
      };
      this.cartList.push(payload);
    }
    // update total cart each time with new item price
    this.cartUpdated.emit(this.cartList.slice());
  };

  // Sales items
  salesItems = async (siparisNo: string) => {
    let checkError = false;
    for (const e of this.cartList) {
      try {
        await this.firebaseService.saleProduct(e);
        await this.firebaseService.addOrderToHistory(
          e,
          siparisNo,
          this.costumer,
          this.cartNote
        );
      } catch (error) {
        checkError = true;
        console.warn(error);
      }
    }
    if (!checkError) {
      this.cartList = [];
      this.cartUpdated.emit(this.cartList.slice());
      this.makeEmptyCart();
    }

    return checkError;
  };
}
