<h2>Teklif Geçmişi</h2>
<p-divider></p-divider>
<div class="export">
  <div>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-excel"
      (click)="exportExcel()"
      class="p-button-success p-mr-2"
      pTooltip="Excel Kaydet"
      tooltipPosition="bottom"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-bars"
      (click)="getData()"
      class="p-button-info p-mr-2"
      pTooltip="Tümünü Listele"
      tooltipPosition="bottom"
    ></button>
  </div>
  <div class="search-area">
    <input
      (keydown.enter)="search(text.value)"
      #text
      pInputText
      type="text"
      placeholder="Arama"
    />
    <button
      style="width: 50px"
      (click)="search(text.value)"
      pButton
      pRipple
      icon="pi pi-search"
    ></button>
    <button
      style="width: 50px"
      (click)="clear(); text.value = null"
      pButton
      pRipple
      icon="pi pi-times"
      class="p-button-help p-ml-1"
    ></button>
  </div>
</div>

<p-accordion *ngIf="!loading">
  <p-accordionTab *ngFor="let order of searchList">
    <ng-template pTemplate="header">
      <div style="width: 100%" class="p-grid p-justify-between p-align-center">
        <span style="margin: 0 20px"
          >Sipriş No: <strong>{{ order.offer.offerId }}</strong></span
        >
        <span style="margin: 0 20px"
          >Müşteri: <strong>{{ order.offer.costumer?.name }}</strong></span
        >
        <span style="margin: 0 20px"
          >Tarih: <strong>{{ order.date | date: 'medium' }}</strong></span
        >
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="header p-grid">
        <h4 class="p-md-3">Malzeme İsmi</h4>
        <h4 class="p-md-1">Malzeme Tipi</h4>
        <h4 class="p-md-1">Miktar</h4>
        <h4 class="p-md-1">KG</h4>
        <h4 class="p-md-1">Birim Fiyatı</h4>
        <h4 class="p-md-1">Birim Satış</h4>
        <h4 class="p-md-1">Dolar Kuru</h4>
        <h4 class="p-md-1">Kar Marjı</h4>
        <h4 class="p-md-1">Fiyat</h4>
        <h4 class="p-md-1">Fiyat TL</h4>
      </div>
      <div
        *ngFor="let offer of order.offer.list"
        class="content p-grid p-align-center"
      >
        <p class="p-md-3">{{ offer.item.name }}</p>
        <p class="p-md-1">{{ offer.item.unitType.name }}</p>
        <p class="p-md-1">{{ offer.quantity }}</p>
        <p class="p-md-1">
          {{
            offer.item.unitType.name === 'Plaka'
              ? offer.item.kgPerUnit * offer.quantity
              : ((offer.item.kgPerUnit * offer.quantity) / 1000).toFixed(2)
          }}
        </p>
        <p class="p-md-1">
          {{ offer.item.cost }} {{ offer.item.costCurrency.abbs }}
        </p>
        <p class="p-md-1">
          {{
            (
              offer.item.cost *
              (order.rates.profit ? 1 + order.rates.profit / 100 : 1.1)
            ).toFixed(2)
          }}
          {{ offer.item.costCurrency.abbs }}
        </p>
        <p class="p-md-1">{{ order.rates.dollar }} TL</p>
        <p class="p-md-1">
          % {{ order.rates.profit ? order.rates.profit : 10 }}
        </p>
        <p class="p-md-1">{{ offer.salesPrice.toFixed(2) }}</p>
        <p class="p-md-1">{{ offer.salesPriceTL.toFixed(2) }}</p>
      </div>
      <div class="top p-grid p-justify-between p-align-center">
        <p class="p-lg-2">
          İşlemi Yapan: <strong>{{ order.user?.name }}</strong>
        </p>
        <p>
          Toplam Fiyat:
          <strong>{{ order.offer.offerTotal.toFixed(2) }} TL</strong>
        </p>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
<p-progressSpinner *ngIf="loading"></p-progressSpinner>
<app-offer
  [backupCostumer]="backupCostumer"
  [backupCartList]="backupList"
></app-offer>
