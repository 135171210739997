<main>
  <div class="currencies">
    <p class="currency">Dolar: {{sharedService.currencyMB?.USD_TRY.toFixed(2)}} ₺</p>
    <p class="currency">Euro: {{sharedService.currencyMB?.EUR_TRY.toFixed(2)}} ₺</p>
  </div>
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-new-item *ngIf="sharedService.newItemPopUp"></app-new-item>
  <app-new-costumer *ngIf="sharedService.newCostumerPopUp"></app-new-costumer>
  <app-settings *ngIf="sharedService.settingsPopUp"></app-settings>
  <app-add-stock *ngIf="sharedService.addStockPopUp"></app-add-stock>
  <app-footer></app-footer>
</main>
