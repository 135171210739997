import { Component, OnInit } from '@angular/core';
import {
  OrderHistoryModal,
} from '../../../modals/history-modals';
import { FirebaseService } from '../../../services/firebase.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { StatesService } from '../../../services/states.service';
import { CartService } from '../../../services/cart.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-order-log',
  templateUrl: './order-log.component.html',
  styleUrls: ['./order-log.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class OrderLogComponent implements OnInit {

  date: Date;
  searchList: OrderHistoryModal[] = [];
  limit = 20;

  constructor(
    private firebaseService: FirebaseService,
    private message: MessageService,
    public state: StatesService,
    private confirm: ConfirmationService,
    private cartService: CartService,
    public shareService:SharedService
  ) {}

  loading = false;

  ngOnInit(): void {
    this.getDataByLimit().then();
  }

  async getAllOrders() {
    this.loading = true;
   await this.firebaseService.getAllOrderHistory().subscribe((response) => {
      this.state.ordersLog = [...response];
      this.searchList = response.sort((a, b) => +b.date - +a.date);
    });
    this.loading = false;
  }

  async getDataByLimit() {
    this.loading = true;
    const payload = [];
    await this.firebaseService.getOrderLogByLimit(this.limit).then((res) => {

      // @ts-ignore
      // tslint:disable-next-line:no-object-literal-type-assertion
      res.forEach((i) => payload.push({...i.data(), id: i.id} as OrderHistoryModal));
    });
    this.state.ordersLog = payload;
    this.searchList = [...payload];
    this.loading = false;
  }

   confirmReturnItem(item){
    this.confirm.confirm({
      message: 'Ürünü iade etmek istediğinize emin misiniz?',
      header: 'İşlemi Onayla',
      icon: 'pi pi-exclamation-triangle',
      accept: ()=> this.returnItem(item),

    })
  }

  async returnItem(item: OrderHistoryModal) {
    // change pallet with true quantity value

    const checkItemExist = await this.firebaseService.getProductById(item.item.id).get()

    if(!checkItemExist.exists){
      this.message.add({
        severity: 'error',
        summary:'Hata!',
        detail:'Bu ürün silinmiş, iade işlemi yapılamaz. Lütfen ürünü tekrar oluşturun.'
      })
      return;
    }

    item.locations.forEach((l) => {
      l.pallet.amountUnit = l.quantity;
    });

    // addstock for each pallet
    await item.locations.forEach((l) => {
      this.firebaseService.addStock(item.item, l.pallet);
    });

    // update order stoy item with return
    const payload: OrderHistoryModal = {
      ...item,
      isReturn: true,
    };
    this.firebaseService
      .updateOrderHistory(payload)
      .then(() => {
        this.message.add({
          severity: 'success',
          summary: 'İade İşlemi Tamamlandı',
          detail: `${item.siparisNo} nolu kayıt işlem geri yüklendi.`,
        });

        this.searchList = this.state.ordersLog.map(order => {
          if(order.id === payload.id){
            return payload
          }
          return order
        })
      })
      .catch((err) => {
        this.message.add({
          severity: 'error',
          summary: 'Hata!',
          detail: err.message,
        });
      });
  }

  getPalets(locations) {
    let result = '';
    locations.forEach((l) => {
      result += l.pallet.storeName + l.pallet.storeShelf;
    });
    return result;
  }

  async exportExcel() {
    await this.getAllOrders().then(() => {
      const exportList = this.state.ordersLog.map((order) => {
        return {
          'Sipariş Numarası': order.siparisNo,
          'Malzeme Adı': order.item.name,
          'Barkod Kodu': order.item.barcodeCode,
          'Çıkış Paletleri': this.getPalets(order.locations),
          'Ürün Tipi': order.item.unitType.name,
          'İşlem Tarihi': new Date(order.date).toLocaleDateString(),
          'İşlem Saati': new Date(order.date).toLocaleTimeString(),
          'Satışı Yapan': order.user?.name,
          'Satış Notu': order.note ? order.note : '',
          'Müşteri Adı': order.costumer?.name,
          'Müşteri Telefon': order.costumer?.mobilePhone,
          'Müşteri Telefon 2': order.costumer?.homePhone,
          'Müşteri Yetkilisi': order.costumer?.officer,
          'İade Durumu': order.isReturn ? 'Evet' : 'Hayır',
          'Toplam Satış': order.quantity + ' ' + order.item.unitType.symbol,
          'Satış Fiyatı': order.salesPrice + ' ' + order.item.costCurrency.abbs,
          'Satış Fiyatı TL': order.salesPriceTL,
        };
      });
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(exportList);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'order_history');
      });
    })

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }


  search(value: string) {
    value = value.toLocaleLowerCase();
    this.searchList = this.state.ordersLog
      .filter(
        (order) =>
          order.item.name.toLocaleLowerCase().includes(value) ||
          order.item.barcodeCode.toLocaleLowerCase().includes(value) ||
          order.siparisNo.toLocaleLowerCase().includes(value) ||
          order.costumer?.name?.toLocaleLowerCase().includes(value) ||
          order.note?.toLocaleLowerCase().includes(value) ||
          order.user?.name?.toLocaleLowerCase().includes(value) ||
          order.item.unitType.name.toLocaleLowerCase().includes(value)
      )
      .sort((a, b) => +b.date - +a.date);
  }

  clear() {
    this.searchList = this.state.ordersLog;
  }

  print(order: OrderHistoryModal) {
    // @ts-ignore
    this.cartService.addToCart(order);
    this.shareService.printPopUp=true;
  }

}
