<div>
  <div class="p-fluid p-my-4 p-input-filled p-lg-12">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Müşteri Seçiniz"
          [showTransitionOptions]="'100ms'"
          scrollHeight="150px"
          [hideTransitionOptions]="'100ms'"
          required
          [virtualScroll]="true"
          itemSize="30"
          [options]="state.costumerGlobal"
          [filter]="true"
          [(ngModel)]="selectedCostumer"
          (click)="getCostumer()"
          optionLabel="name"
          emptyFilterMessage="Ürün Bulunamadı!"
        ></p-dropdown>
      </span>
  </div>
  <form
    *ngIf="selectedCostumer"
    [formGroup]="newCostumerForm"
    class="content"
  >
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="name"
          id="name"
          type="text"
          pInputText
        />
        <label for="name">Firma Adı</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="officer"
          id="officer"
          type="text"
          pInputText
        />
        <label for="officer">Firma Yetkilisi</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="email"
          id="email"
          type="email"
          pInputText
        />
        <label for="email">E-Mail Adresi</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
         <p-inputMask mask="(999) 999-9999"
                      formControlName="mobilePhone"
                      id="mobilePhone"
                      required></p-inputMask>
        <label for="mobilePhone">Telefon Numarası 1</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
                <p-inputMask mask="(999) 999-9999"
                             formControlName="homePhone"
                             id="homePhone"
                ></p-inputMask>
        <label for="homePhone">Telefon Numarası 2</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <textarea
          formControlName="address"
          id="address"
          type="text"
          pInputTextarea
          rows="3"
        ></textarea>
        <label for="address">Adres</label>
      </span>
    </div>
    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-warning"
        (click)="handleClear()"
        class="p-m-2"
        label="İptal"
      ></p-button>
      <p-button
        styleClass="p-button-success"
        type="submit"
        class="p-m-2"
        label="Güncelle"
        (click)="updateCostumer()"
      ></p-button>
      <p-button
        styleClass="p-button-danger"
        (click)="deleteCostumer()"
        type="submit"
        class="p-m-2"
        label="Sil"
      ></p-button>
    </div>
  </form>
</div>
<p-toast></p-toast>
