export class CompanyInfo {
  public static address = `İkitelli Org. San. Demirciler Sitesi F1 Blok Kapı no: 430 -431 - 432`;
  public static city = `Küçükçekmece - İSTANBUL`;
  public static phone = `(0212) 671 82 38 - 39 - 40`;
  public static selfPhone = `0541 313 08 37`;
  public static vergiDairesi = `VD: İKİTELLİ - 705 003 5197`;
  public static ibanGarantiTl = `TR 56 0006 2000 0190 0006 202671`;
  public static ibanGarantiUSD = `TR 79 0006 2000 0190 0009 0901 41`;
  public static ibanYapiKrediTL = `TR 69 0006 7010 0000 0082 4606 90`;
  public static ibanYapiKrediUSD = `TR 34 0006 7010 0000 0082 5365 92 `;
  public static ibanTurkiyeIsBankasiTL = `TR 98 0006 4000 0011 3720 0107 13`;
  public static ticariIsim = 'Özsoy Metal ve Paslanmaz SAN. LTD. ŞTİ.';
  public static email= 'info@ozsoymetalpaslanmaz.com';
}
