import { Component, Input, OnInit } from '@angular/core';
import {
  ProductModal,
  ProductPalletModal,
} from '../../../modals/product.modal';
import { SharedService } from '../../../services/shared.service';
import { CartService } from '../../../services/cart.service';
import { SalesItemModal } from '../../../modals/sales-item.modal';
// tslint:disable-next-line:no-implicit-dependencies
import { v4 as uuidv4 } from 'uuid';
import { StatesService } from '../../../services/states.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
  @Input() product: ProductModal;

  constructor(
    public sharedService: SharedService,
    private cartService: CartService,
    private stateService: StatesService
  ) {}

  ngOnInit(): void {
    if(!this.sharedService.isStockLocked && this.product.pallets.length < 1){
      this.product.pallets.push({
        id: uuidv4(),
        storeName: 'Z',
        storeShelf: '1',
        amountUnit:10000000
      })
    }
  }

  addToCart(event: any, pallet: ProductPalletModal) {
    const inputQuantity = +event.target.inputAmount.value;
    const location = {
      pallet,
      quantity: inputQuantity,
    };

    const item: SalesItemModal = {
      id: uuidv4(),
      item: this.product,
      salesPrice: null,
      quantity: inputQuantity,
      locations: [location],
      maxStockAmount: pallet.amountUnit,
    };
    this.cartService.addToCart(item);
    this.product.pallets.forEach((p) => {
      if (p.id === pallet.id) {
        p.amountUnit = p.amountUnit - inputQuantity;
      }
    });
    this.stateService.updateProductPallet(this.product);
    event.target.inputAmount.value = 0;
    this.sharedService.cartPopUp = false;
  }
}
