<p-card class="p-mx-2">
  <div class="p-text-center p-text-italic p-grid p-align-center border-filled">
    <div *ngIf="cartService.costumer" class="p-fluid p-input-filled p-lg-4">
      <span class="p-float-label">
        <input
          id="costumer"
          type="text"
          disabled
          pInputText
          [value]="cartService.costumer?.name"
        />
        <label for="costumer">Müşteri</label>
      </span>
    </div>
    <div *ngIf="cartService.cartNote" class="p-fluid p-input-filled p-lg-8">
      <span class="p-float-label">
        <input
          id="note"
          type="text"
          disabled
          pInputText
          [value]="cartService.cartNote"
        />
        <label for="note">Not</label>
      </span>
    </div>
  </div>

  <div *ngIf="cartList.length > 0">
    <ul style="width: 100%; padding: 0">
      <li *ngFor="let item of cartList">
        <div class="p-grid">
          <div class="p-md-3 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                #name
                id="name"
                type="text"
                disabled
                pInputText
                [value]="item.item.name"
              />
              <label for="name">Ürün Adı</label>
            </span>
          </div>
          <div class="p-md-2 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                #barcode
                id="barcode"
                type="text"
                disabled
                pInputText
                [value]="item.item.barcodeCode"
              />
              <label for="barcode">Barkod Numarası</label>
            </span>
          </div>
          <div class="p-md-3 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                #place
                id="place"
                type="text"
                disabled
                pInputText
                [value]="getLocations(item)"
              />
              <label for="place">Yer</label>
            </span>
          </div>
          <div class="p-md-2 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                #cost
                id="cost"
                type="number"
                min="0"
                pInputText
                [disabled]="sharedService.isStockLocked"
                [value]="item.item.cost"
                (change)="updateSalesPrice(item, cost.value)"
              />
              <label for="cost"
                >Birim Maliyet / {{ item.item.costCurrency.abbs }}</label
              >
            </span>
          </div>
          <div class="p-md-2 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                #cost
                id="dollarSales"
                type="number"
                min="0"
                pInputText
                disabled
                [value]="
                  (
                    item.item.cost *
                    (1 + sharedService.profitRate / 100)
                  ).toFixed(1)
                "
                (change)="updateSalesPrice(item, cost.value)"
              />
              <label for="cost"
                >Birim Satış / {{ item.item.costCurrency.abbs }}</label
              >
            </span>
          </div>
        </div>
        <div *ngIf="sharedService.dollarRate" class="p-grid">
          <div class="p-md-1 p-sm-6 p-mt-2">
            <button (click)="deleteItem(item)" pButton label="Sil"></button>
          </div>
          <div class="p-md-5"></div>
          <div
            *ngIf="
              !item.item.name.trim().toLocaleLowerCase().includes('işçilik')
            "
            class="p-md-1 p-sm-6 p-fluid p-input-filled"
          >
            <span class="p-float-label">
              <input
                class="blue-text"
                #amount
                id="amount"
                type="number"
                min="0"
                pInputText
                disabled
                [value]="item.quantity"
              />
              <label for="amount">{{ item.item.unitType.name }}</label>
            </span>
          </div>
          <div
            *ngIf="
              !item.item.name.trim().toLocaleLowerCase().includes('işçilik')
            "
            class="p-md-1 p-sm-6 p-fluid p-input-filled"
          >
            <span class="p-float-label">
              <input
                #totalKg
                id="totalKg"
                type="number"
                pInputText
                disabled
                class="blue-text"
                [value]="
                  item.item.unitType.name === 'Plaka'
                    ? item.item.kgPerUnit * item.quantity
                    : ((item.item.kgPerUnit * item.quantity) / 1000).toFixed(2)
                "
              />
              <label for="totalKg">Toplam KG</label>
            </span>
          </div>
          <div class="p-md-2 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                class="blue-text"
                #itemPrice
                id="itemSales"
                type="number"
                min="0"
                pInputText
                disabled
                [value]="item.salesPrice.toFixed(2)"
              />
              <label for="itemSales"
                >Satış {{ item.item.costCurrency.name }}</label
              >
            </span>
          </div>
          <div class="p-md-2 p-sm-6 p-fluid p-input-filled">
            <span class="p-float-label">
              <input
                class="blue-text"
                #calculate
                id="itemPrice"
                type="number"
                min="0"
                pInputText
                disabled
                [value]="calculateItem(item).toFixed(2)"
              />
              <label for="itemPrice">Satış / ₺</label>
            </span>
          </div>
        </div>
      </li>
    </ul>
    <h3
      appTextColor="warning"
      *ngIf="!sharedService.dollarRate || !sharedService.kdvRate"
    >
      Lütfen Kur / KDV Bilgisini Giriniz.
    </h3>
    <div class="p-mx-2 p-grid p-jc-between p-my-2 p-align-center">
      <div class="buttons">
        <button
          [disabled]="!cartService.costumer"
          (click)="sharedService.offerScreen = true"
          class="p-button-info"
          pButton
          label="Teklif Ver"
        ></button>
        <button
          (click)="sharedService.selectCostumer = true"
          class="p-button-help"
          pButton
          label="Müşteri / Not Ekle"
        ></button>
      </div>

      <p class="total-price label">
        Toplam:<strong
          >{{ cartService.calculateWithKdv().toFixed(2) }} ₺</strong
        >
      </p>
    </div>
  </div>

  <div *ngIf="!(cartList.length > 0)">
    <p class="p-text-center p-text-secondary">Sepette ürün yok.</p>
  </div>
</p-card>

<app-order-completed></app-order-completed>
<app-offer></app-offer>
<app-select-costumer></app-select-costumer>
