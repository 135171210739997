import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { CartService } from '../../services/cart.service';
import { MessageService } from 'primeng/api';
import { SalesItemModal } from '../../modals/sales-item.modal';
import { CompanyInfo } from '../../utils/company-info';
// tslint:disable-next-line:no-implicit-dependencies
import { v4 as uuidv4 } from 'uuid';
import { FirebaseService } from '../../services/firebase.service';
import { StatesService } from '../../services/states.service';
import { CostumerModal } from '../../modals/costumer.modal';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  providers: [MessageService],
})
export class OfferComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    public cartService: CartService,
    private messageService: MessageService,
    private firebaseService: FirebaseService,
    public state:StatesService
  ) {}

  printList: Array<SalesItemModal> = [];
  isLoading = false;
  siparisNo: string;
  isOfferApplied = false;
  @Input() backupCartList: SalesItemModal[];
  @Input() backupCostumer: CostumerModal;

  companyInfo = {
    address: CompanyInfo.address,
    city: CompanyInfo.city,
    name: CompanyInfo.ticariIsim,
    vergi: CompanyInfo.vergiDairesi,
    ibanGarantiTl: CompanyInfo.ibanGarantiTl,
    ibanGarantiUSD: CompanyInfo.ibanGarantiUSD,
    ibanYapiKrediTL: CompanyInfo.ibanYapiKrediTL,
    ibanYapiKrediUSD: CompanyInfo.ibanYapiKrediUSD,
    ibanTurkiyeIsBankasiTL: CompanyInfo.ibanTurkiyeIsBankasiTL,
    phone: CompanyInfo.phone,
    selfPhone: CompanyInfo.selfPhone,
    mail: CompanyInfo.email,
  };

  printStyle = {
    h3: {
      'text-align': 'left',
      'font-size': '0.8rem',
    },
    '.header-container': {
      display: 'flex',
      'justify-content': 'space-between',
    },
    'm-left': {
      'margin-left': '10px',
    },
    '.order-text': {
      'font-size': '0.8rem',
    },
    '.top-area': {
      display: 'flex',
      'justify-content': 'space-between',
      width: '100%',
      margin: '20px auto 40px auto',
    },
    '.company-info p': {
      'font-size': '0.55rem',
    },
    '.musteri': {
      margin: 'auto 0',
    },
    '.musteri p': {
      'font-size': '0.6rem',
    },
    '.text-align-right': {
      'text-align': 'right',
    },
    table: {
      width: '100%',
      'border-collapse': 'collapse',
    },
    tr: {
      width: '100%',
      'min-height': '100px',
      'border-bottom': '1px solid black',
      padding: '10px',
    },
    td: {
      'text-align': 'center',
      'font-size':'0.8rem',
      height: '60px',
      padding: '10px 0',
    },
    th: {
      'background-color': '#fde5c6',
      'font-size':'0.8rem',
    },
    '.quantity': {
      'font-weight': '900',
    },
    '.orderNo': {
      'font-weight': 'bold',
      'background-color': '#ffbdbd',
    },
    '.iban-container': {
      position: 'absolute',
      bottom: '10px',
      width: '100%',
    },
    '.iban-content': {
      display: 'grid',
      'grid-template-columns': '50% 50%',
      'column-gap': '5px',
      'row-gap': '5px',
    },
    '.iban-item': {
      display: 'flex',
      'flex-flow': 'row wrap',
      'justify-content': 'space-between',
      'font-size': '0.8rem',
    },
    '.iban-4': {
      flex: '0 0 35%',
    },
    '.iban-8': {
      flex: '0 0 65%',
    },
    '.quate': {
      margin: '0 auto',
      'font-size': '10px',
      'font-weight': 'bold',
      'text-align:': 'center',
    },
    '.print-button': {
      display: 'none',
    },
    '.price-box': {
      display: 'flex',
      'justify-content': 'flex-end',
    },
    '.price-container': {
      margin: '15px 0',
    },
    '.center': {
      'text-align': 'center',
    },
    '.price-item': {
      padding: '0 10px',
      display: 'flex',
      'justify-content': 'space-between',
      'border-bottom': '2px solid lightgray',
      'align-items': 'center',
    },
    '.price-tag': {
      'font-weight': 'bold',
      'font-size': '1rem',
      'margin-right': '5px',
    },
    '.kdv-text': {
      color: '#0074f8',
    },
    '.note-container': {
      'font-size': '0.8rem',
    },
    '.bold': {
      'font-weight': 'bold',
    },
    '.total-text': {
      'font-size': '1.2rem',
      'font-weight': 'bold',
      color: '#750404',
    },
    'note-text': {
      'font-size': '8px'
    }
  };
  ngOnInit(): void {
    this.printList = this.cartService.getCartItems();
    this.siparisNo = uuidv4().slice(0, 8).toLocaleUpperCase();
    this.cartService.cartUpdated.subscribe((res) => (this.printList = res));
  }
  async print() {
    this.isOfferApplied = true;
    const payload = {
      offerId: this.siparisNo,
      list: this.printList,
      costumer: this.cartService.costumer ? this.cartService.costumer : '',
      note: this.cartService.cartNote ? this.cartService.cartNote : '',
      offerBase: this.cartService.calculateBasePrice(),
      offerTax: this.cartService.calculateKDV(),
      offerTotal: this.cartService.calculateWithKdv(),
    };

      this.firebaseService
        .addOfferLog(payload)
        .then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Teklif kaydedildi.',
            detail: `${this.siparisNo} nolu teklif oluşturuldu!`,
          });
          this.cartService.makeEmptyCart();
          this.sharedService.offerScreen = false;
        })
        .catch((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Hata! Teklif kaydedilemedi.',
            detail: err.message,
          });
        });

  }

  getToday() {
    const date: Date = new Date();
    return date.toLocaleDateString() + ' / ' + date.toLocaleTimeString();
  }

  calculateItem = (item) => {
    return this.cartService.calculateCartItem(
      item.salesPrice,
      item.item.costCurrency.name
    );
  };

  closePage() {
    if (this.backupCartList) {
      this.cartService.setCartItems(this.backupCartList, this.backupCostumer);
    } else {
      this.cartService.makeEmptyCart();
    }
    this.sharedService.offerScreen = false;
  }

  checkIsAdet(item:SalesItemModal) {
    return item.item.unitType.name.includes('Adet');
  }

  calculateQuantity(item:SalesItemModal){
    if(item.item.unitType.name.includes('Adet')){
      return '-'
    } else if (item.item.unitType.name.includes('Plaka')){
      return item.quantity
    } else {
      return item.quantity + ' ( ' + (item.quantity / item.item.perItem).toFixed(1) + 'Boy )'
    }
  }
}
