<div class="reports-container">

  <p-tabView>
    <p-tabPanel header="Satış Geçmişi">
      <app-order-log></app-order-log>
    </p-tabPanel>
    <p-tabPanel header="Teklif Geçmişi">
      <app-offer-log></app-offer-log>
    </p-tabPanel>
    <p-tabPanel header="Stok Ekleme Geçmişi">
      <app-stock-log></app-stock-log>
    </p-tabPanel>
    <p-tabPanel disabled header="Sayılarla">
      <app-dasboard></app-dasboard>
    </p-tabPanel>
  </p-tabView>
</div>
