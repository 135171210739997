export class CollectionsNames {
  public static PRODUCTS = 'products';
  public static USERS = 'users';
  public static STORES = 'stores';
  public static COSTUMERS = 'costumers';
  public static ORDERS = 'orders';
  public static HISTORY = 'history';
  public static STORE_HISTORY = 'store_history';
  public static STOCK_HISTORY = 'stock_history';
  public static OFFER_HISTORY = 'offer_history';
  public static SETTINGS = 'settings';
}
