import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(res => {
      if (res?.role === 'admin') {
        this.router.navigate(['/home']).then();
      } else {
        this.router.navigate(['stock-status'])
      }
    });
  }

  login(form: NgForm) {
    const {username, password} = form.value;
    
    this.authService.signIn(username, password).then(() => {
      this.messageService.add({severity: 'success', summary: 'Başarılı', detail: 'Giriş Başarılı'});
    }).catch(err => {
      this.messageService.add({severity: 'error', summary: 'Hata!', detail: err.message});
    }).finally(() => form.reset());
  }

  clear(form: NgForm) {
    form.reset();
  }
}
