import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductListComponent } from './screens/product-list/product-list.component';
import { NewStoreComponent } from './components/add-new/new-store/new-store.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './screens/layout/layout.component';
import { Page404Component } from './components/page404/page404.component';
import { AuthGuard } from './auth.guard';
import { CartComponent } from './screens/cart/cart.component';
import { ReportsComponent } from './screens/reports/reports.component';
import { OrderCompletedComponent } from './screens/order-completed/order-completed.component';
import { AddShielfComponent } from './components/add-new/add-shielf/add-shielf.component';
import { DeleteProductComponent } from './screens/delete-product/delete-product.component';
import { EditProductComponent } from './components/settings-componenets/edit-product/edit-product.component';
import { ProductMoveComponent } from './components/settings-componenets/product-move/product-move.component';
import { StockLogComponent } from './components/reports-components/stock-log/stock-log.component';
import { EditCostumerComponent } from './screens/edit-costumer/edit-costumer.component';
import { StockStatusComponent } from './screens/stock-status/stock-status.component';
import { UserSettingsComponent } from './screens/user-settings/user-settings.component';
import { MoveProductItemComponent } from './components/settings-componenets/move-product-item/move-product-item.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'home',
    component: LayoutComponent,
    data: {role:'admin'},
    children: [
      { path: '', component: ProductListComponent },
      { path: 'add-new-store', component: NewStoreComponent },
      { path: 'add-new-shelf', component: AddShielfComponent },
      { path: 'cart', component: CartComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'order-completed', component: OrderCompletedComponent },
      { path: 'delete-product', component: DeleteProductComponent },
      { path: 'edit-product', component: EditProductComponent },
      { path: 'move-products', component: ProductMoveComponent },
      { path: 'stock-history', component: StockLogComponent },
      { path: 'edit-costumer', component: EditCostumerComponent },
      { path: 'user-settings', component: UserSettingsComponent },
      { path: 'move-item', component: MoveProductItemComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'stock-status',
    component: StockStatusComponent,
    data:{role:'user'},
    canActivate: [AuthGuard]
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
