<div class="container">
    <p-table
      #dt
      [value]="tableService.arrayData"
      dataKey="id"
      [rowHover]="true"
      styleClass="p-datatable-sm"
      [rows]="50"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[50, 100]"
      [loading]="loading"
      [paginator]="true"
      currentPageReportTemplate="Sayfa {first} / {last} gösteriliyor. Toplam {totalRecords} giriş var."
      [filterDelay]="0"
      [globalFilterFields]="['name', 'barcodeCode']"
      sortField="stockStatusValue"
    >
      <ng-template pTemplate="caption">
        <div class="table-header">
            <button (click)="logOut()" pButton label="Çıkış Yap"></button>
          <span class="table-title">Ürün Listesi Tablosu</span>

          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #searchInput
              (input)="dt.filterGlobal(searchInput.value, 'contains')"
              placeholder="Ara"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="barcodeCode">
            <div class="p-d-flex p-jc-between p-ai-center">
              Barkod
              <p-sortIcon field="barcodeCode"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="barcodeCode"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
          <th style="width: 20em;" pSortableColumn="name">
            <div class="p-d-flex p-jc-between p-ai-center">
              Ürün Adı
              <p-sortIcon field="name"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="name"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="perItem">
            <div class="p-d-flex p-jc-between p-ai-center">
              Toplam
              <p-sortIcon field="perItem"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="perItem"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="perItem">
            <div class="p-d-flex p-jc-between p-ai-center">
              Boy
              <p-sortIcon field="perItem"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="perItem"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="kgPerUnit">
            <div class="p-d-flex p-jc-between p-ai-center">
              Birim KG
              <p-sortIcon field="kgPerUnit"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="kgPerUnit"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="totalKg">
            <div class="p-d-flex p-jc-between p-ai-center">
              Toplam KG
              <p-sortIcon field="totalKg"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="totalKg"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="pallets">
            <div class="p-d-flex p-jc-between p-ai-center">
              Yer
              <p-sortIcon field="displayStore"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="displayStore"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="stockStatusValue">
            <div class="p-d-flex p-jc-between p-ai-center">
              Stok Durumu
              <p-sortIcon field="stockStatusValue"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="stockStatusValue"
                display="menu"
                class="p-ml-auto"
              ></p-columnFilter>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr class="p-selectable-row">
          <td>
            {{ product.barcodeCode }}
          </td>
          <td>
            {{ product.name }}
          </td>
          <td>
            {{ product.isUnitExist  }}
          </td>
          <td>
            {{ product.calculatedItem }} Boy
          </td>

          <td>
            {{ product.kgPerUnit?.toFixed(2) }}
          </td>
          <td [appTextColor]="product.totalKg === tableService.errorMessage ? 'warning' : ''">
            {{ product.totalKg }}
          </td>
          <td
            [appTextColor]="
              product.displayStore === tableService.errorMessage ? 'warning' : ''
            "
          >
            {{ product.displayStore }}
          </td>

          <td>
            <span [class]="'product-badge status-' + product.stockStatusCss">{{
              product.stockStatusValue
            }}</span>
          </td>
        </tr>

      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">Ürün bulunamadı..</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
