import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import { OfferHistoryModal } from '../../../modals/history-modals';
// tslint:disable-next-line:no-implicit-dependencies
import * as _ from 'lodash';
import { SharedService } from '../../../services/shared.service';
import { CartService } from '../../../services/cart.service';
import { SalesItemModal } from '../../../modals/sales-item.modal';
import { CostumerModal } from '../../../modals/costumer.modal';

@Component({
  selector: 'app-offer-log',
  templateUrl: './offer-log.component.html',
  styleUrls: ['./offer-log.component.scss'],

})
export class OfferLogComponent implements OnInit {
  offerList: OfferHistoryModal[] = [];
  searchList: OfferHistoryModal[];
  constructor(private fs: FirebaseService, public shared:SharedService, private cart:CartService) {}
  limit = 20;
  loading = false;
  backupList:SalesItemModal[];
  backupCostumer:CostumerModal;
  ngOnInit(): void {
    this.getDataByLimit();
  }

  private getDataByLimit() {
    this.loading = true;
    this.fs
      .getOfferLogByLimit(this.limit)
      .then((res) => {
        // tslint:disable-next-line:no-object-literal-type-assertion
        res.forEach((i) => this.offerList.push({...i.data(), id:i.id} as OfferHistoryModal));
        this.searchList = [...this.offerList];
      })
      .catch((err) => console.warn(err))
      .finally(() => (this.loading = false));
  }

  public getData() {
    this.loading = true;
    this.fs.getOfferLogs().subscribe(
      (response) => {
        this.offerList = response;
        this.searchList = response.sort((a, b) => b.date - a.date);
        this.loading = false;
      },
      (error) => {
        console.warn(error);
        this.loading = false;
      }
    );
  }

  async exportExcel() {
    const exportList = [];
    this.fs.getOfferLogs().subscribe((response) => {
      response.map((offer, i) => {
        const item = offer.offer.list.map((l) => {
          return {
            sıra: i + 1,
            'Teklif Numarası': offer.offer.offerId,
            'Müşteri Adı': offer.offer.costumer?.name,
            'Müşteri Yetkisi': offer.offer.costumer?.officer,
            'Teklif Fiyatı': offer.offer.offerTotal,
            'Teklifi Hazırlayan': offer.user?.name,
            'Teklif Tarihi': new Date(offer.date).toLocaleTimeString(),
            'Teklif Saati': new Date(offer.date).toLocaleDateString(),
            'Malzeme Adı': l.item.name,
            'Kar Marjı':offer.rates.profit,
            'Kur Tipi': l.item.costCurrency.name,
            // tslint:disable-next-line:max-line-length
            'Kur Fiyatı': l.item.costCurrency.name.toLocaleLowerCase() === 'türk lirası' ? '-' : l.item.costCurrency.name.toLocaleLowerCase() === 'dolar' ? offer.rates.dollar : offer.rates.euro,
            'Malzeme Miktari': l.quantity,
            'Malzeme Türü': l.item.unitType.name,
            'Malzeme Fiyatı': l.salesPrice,
            'Malzeme Fiyatı TL': l.salesPriceTL,
          };
        });
        exportList.push(item);
      });
      const payload = _.flattenDeep(exportList);
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(payload);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'offer_history');
      });
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  search(value: string) {
    value = value.toLocaleLowerCase();
    this.searchList = this.offerList
      .filter((h) => {
        return (
          h.offer.costumer?.name?.toLocaleLowerCase().includes(value) ||
          h.offer.offerId.toLocaleLowerCase().includes(value) ||
          h.offer.note?.toLocaleLowerCase().includes(value) ||
          h.offer.list.some((l) =>
            l.item.name.toLocaleLowerCase().includes(value)
          ) ||
          h.offer.list.some((l) =>
            l.item.barcodeCode.toLocaleLowerCase().includes(value)
          )
        );
      })
      .sort((a, b) => b.date - a.date);
  }

  clear() {
    this.searchList = this.offerList;
  }

  openOffer(item:SalesItemModal[], c:CostumerModal){
    this.backupList = this.cart.getCartItems();
    this.backupCostumer = this.cart.costumer;
    this.cart.setCartItems(item,c);
    this.shared.offerScreen=true;
  }
}
