import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import {
  StockHistoryModal,
} from '../../../modals/history-modals';
import { ConfirmationService, MessageService } from 'primeng/api';
// tslint:disable-next-line:no-implicit-dependencies
import * as _ from 'lodash';
import { ProductModal } from '../../../modals/product.modal';

@Component({
  selector: 'app-stock-log',
  templateUrl: './stock-log.component.html',
  styleUrls: ['./stock-log.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class StockLogComponent implements OnInit {
  stockList: StockHistoryModal[];
  searchList: StockHistoryModal[];
  loading = false;
  limit = 20;
  constructor(
    private fs: FirebaseService,
    private msg: MessageService,
    private confirm: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.getDataByLimit().then();
  }

  getAllData() {
    this.loading = true;
    this.fs.getStockLogs().subscribe(
      (response) => {
        this.stockList = response;
        this.searchList = response.sort((a, b) => b.date - a.date);
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  async getDataByLimit() {
    this.loading = true;
    const payload = [];
    await this.fs.getStockLogByLimit(this.limit).then((res) => {
      // tslint:disable-next-line:no-object-literal-type-assertion
      res.forEach((i) => payload.push({...i.data(), id: i.id} as StockHistoryModal));
    });
    this.searchList = [...payload];
    this.stockList = [...payload];
    this.loading = false;
  }
  search(value: string) {
    value = value.toLocaleLowerCase();
    this.searchList = this.stockList
      .filter((h) => {
        return (
          h.product.barcodeCode.toLocaleLowerCase().includes(value) ||
          h.product.name.toLocaleLowerCase().includes(value) ||
          h.product.unitType.name.toLocaleLowerCase().includes(value)
        );
      })
      .sort((a, b) => b.date - a.date);
  }

  exportExcel() {
    const exportList = this.stockList.map((stock) => {
      return {
        'Eklenen Malzeme Adı': stock.product.name,
        'Eklenen Malzeme Barkodu': stock.product.barcodeCode,
        'Eklenen Malzeme Türü': stock.product.unitType.name,
        'Maliyet Kuru': stock.product.costCurrency.name,
        'Eski Maliyet': stock.product.oldCost,
        Maliyet: stock.product.cost,
        Tarih: new Date(stock.date).toLocaleDateString(),
        Saat: new Date(stock.date).toLocaleTimeString(),
        'Eklendiği Dükkan': stock.addedStock.storeName,
        'Eklendiği Raf': stock.addedStock.storeShelf,
        Miktarı: stock.addedStock.amountUnit,
        Ölçü: stock.product.unitType.symbol,
        'İşlemi Yapan': stock.user?.name,
        'İptal Durumu': stock.isCancel ? 'İptal Edildi' : '',
      };
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'stock_history');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }


  async returnItem(stock: StockHistoryModal) {
    const product = await this.fs.getProductById(stock.product.id).get();
    let restItem = 0;
    if (product.exists) {
      const data = product.data();

      // remove palet stocks
      data.pallets = data.pallets.map((p) => {

        if (p.id === stock.addedStock.id) {
          restItem = p.amountUnit;
          p.amountUnit -= stock.addedStock.amountUnit;
        }

        // if pallets has 0 amount, mark it as a undefined.
        if (p.amountUnit > 0) {
          return p;
        }
      });

      // Clear array from all undefined data coming from map from above
      data.pallets = _.compact(data.pallets);

      // remove units from total units
      if (data.totalAmountUnit < stock.addedStock.amountUnit) {
        return this.msg.add({
          severity: 'error',
          summary: 'Hata',
          detail: 'Silmek istediğiniz kadar ürün stokta yoktur!',
        });
      }

      if(data.totalAmountUnit){
        data.totalAmountUnit -= stock.addedStock.amountUnit;
      }

      // remove units kg from totalkg
      data.totalAmountKg -=
        stock.addedStock.amountUnit * stock.product.kgPerUnit;
        const calculateSold = stock.addedStock.amountUnit - restItem;
      if (0 < calculateSold) {
        this.confirm.confirm({
          message: `Silmek istediğiniz stoktan ${Math.abs(calculateSold)} ${
            data.unitType.symbol
          } satış gerçekleşmiş. Kalanını silmek istermisiniz?`,
          accept: () => this.deleteItem(stock, data, restItem),
          reject: () => {
            return;
          },
          acceptButtonStyleClass: 'p-button-success',
          rejectButtonStyleClass: 'p-button-info',
        });
      } else {
        this.confirm.confirm({
          message: 'Stok bilgisini silinecek onaylıyor musunuz?',
          accept: () => this.deleteItem(stock, data, restItem),
          reject: () => {
            return;
          },
          acceptButtonStyleClass: 'p-button-success',
          rejectButtonStyleClass: 'p-button-info',
        });
      }
    } else {
      this.msg.add({
        severity: 'error',
        summary: 'Ürün Bulunamadı!',
        detail:
          'Seçtiğiniz ürünün tüm stokları tükenmiş. Bu nedenle stok silme işlemi gerçekleştirilemez.',
      });
    }
  }

  deleteItem(stock: StockHistoryModal, data: ProductModal, removeUnit) {
    this.fs
      .updateProduct(data)
      .then(() => {
        this.fs.updateStockLog({ ...stock, isCancel: true }).then(() => {
          this.msg.add({
            severity: 'success',
            summary: 'Silindi!',
            detail: `${stock.product.name} üründen, ${Math.abs(
              removeUnit
            )} stok silindi.`,
          });
          this.searchList = this.stockList.map(s => {
            if(s.id === stock.id){
              return  {
                ...stock,
                isCancel:true
              }
            }
            return s
          })
        });
      })
      .catch((err) => {
        this.msg.add({
          severity: 'error',
          summary: 'Hata',
          detail: err,
        });
      })


  }
  clear() {
    this.searchList = this.stockList;
  }
}
