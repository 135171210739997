import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService,ConfirmationService } from 'primeng/api';
import { SharedService } from '../../services/shared.service';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class SettingsComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    public sharedService: SharedService,
    private firebaseService: FirebaseService,
    private confirm : ConfirmationService,
  ) {}
  KDVRATES = [
    { kdv: 18, name: '18' },
    { kdv: 9, name: '9' },
    { kdv: 0, name: '0' },
  ];
  settingForm = new FormGroup({
    euroRate: new FormControl(this.sharedService.euroRate),
    dollarRate: new FormControl(this.sharedService.dollarRate),
    profitRate: new FormControl(this.sharedService.profitRate),
    kdvRate: new FormControl(this.sharedService.kdvRate),
  });
  password: number;

  ngOnInit(): void {}

  handleUpdate(): void {
    const {value} = this.settingForm;
    if(value.dollarRate < this.sharedService.currencyMB?.USD_TRY){
      this.confirm.confirm({
        message: 'Girdiğiniz dolar kuru merkez bankasından düşük!',
        accept: ()=> this.saveSettings(),
        acceptLabel:'Kaydet',
        rejectLabel:'İptal',
        acceptButtonStyleClass:'p-button-success',
        rejectButtonStyleClass: 'p-button-danger',
      })

    } else if(value.euroRate < this.sharedService.currencyMB?.EUR_TRY) {
      this.confirm.confirm({
        message: 'Girdiğiniz euro kuru merkez bankasından düşük!',
        accept: ()=> this.saveSettings(),
        acceptLabel:'Kaydet',
        rejectLabel:'İptal',
        acceptButtonStyleClass:'p-button-success',
        rejectButtonStyleClass: 'p-button-danger',

      })
    } else {
      this.saveSettings();
    }


  }

  private saveSettings() {
    if (this.settingForm.get('profitRate').dirty) {
      if (+this.password === this.sharedService.password) {
        this.sharedService.updateSettings(this.settingForm.value);
        this.firebaseService
          .addUpdateSettings(this.settingForm.value)
          .then(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Başarıyla Eklendi.',
              detail: ` Ayarlar Güncellendi!`,
            });
            this.sharedService.toggleSettingsPopUp();
          });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Şifre Hatalı!',
          detail: 'Kar marjını değiştirmek için şifre onayı gereklidir.',
        });
      }
    } else {
      this.sharedService.updateSettings(this.settingForm.value);
      this.firebaseService
        .addUpdateSettings(this.settingForm.value)
        .then(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Başarıyla Eklendi.',
            detail: ` Ayarlar Güncellendi!`,
          });
          this.sharedService.toggleSettingsPopUp();
        });
    }
  }

  handleClear(): void {
    this.settingForm.reset({
      euroRate: this.sharedService.euroRate,
      dollarRate: this.sharedService.dollarRate,
      profitRate: this.sharedService.profitRate,
      kdvRate: +this.sharedService.kdvRate,
    });
  }
}
