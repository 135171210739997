import { Component, OnInit, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { HeaderComponent } from './components/header/header.component';
import { SharedService } from './services/shared.service';
import { Router, NavigationError, NavigationEnd } from '@angular/router';
import * as rg4js from 'raygun4js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    public sharedState: SharedService,
    private router: Router
  ) {}

  @ViewChild(HeaderComponent) header;
  title = 'stock-managment';

  footer = false;

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.initialErrorTracking();
  }

  initialErrorTracking() {
    this.router.events.subscribe((event) => {
      // Track page views when the NavigationEnd event occurs
      if (event instanceof NavigationEnd) {
        rg4js('trackEvent', {
          type: 'pageView',
          path: event.url,
        });
      }
      if (event instanceof NavigationError) {
        // Track navigation error
        rg4js('send', {
          error: event.error,
        });
      }
    });
  }
}
