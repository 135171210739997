import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID,ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrimeNgModule } from './modules/prime-ng/prime-ng.module';
import { ProductListComponent } from './screens/product-list/product-list.component';
import { ProductItemComponent } from './screens/product-list/product-item/product-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextColorDirective } from './directives/text-color.directive';
import { UnlessDirective } from './directives/unless.directive';
import { NewItemComponent } from './components/add-new/new-item/new-item.component';
import { NewStoreComponent } from './components/add-new/new-store/new-store.component';
import { NewCostumerComponent } from './components/add-new/new-costumer/new-costumer.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './screens/layout/layout.component';
import { Page404Component } from './components/page404/page404.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { RaygunErrorHandler } from './app.raygun.setup';
// tslint:disable-next-line: no-submodule-imports
import { MessageModule } from 'primeng/message';
import { AddStockComponent } from './components/add-new/add-stock/add-stock.component';
import { ReportsComponent } from './screens/reports/reports.component';
import { CartComponent } from './screens/cart/cart.component';
import { SettingsComponent } from './components/settings/settings.component';
// tslint:disable-next-line: no-implicit-dependencies tslint:disable-next-line: no-submodule-imports
import { environment } from 'src/environments/environment';
import { OrderCompletedComponent } from './screens/order-completed/order-completed.component';
import { NgxPrintModule } from 'ngx-print';
import { OfferComponent } from './screens/offer/offer.component';
import { SelectCostumerComponent } from './components/select-costumer/select-costumer.component';
import { HttpClientModule } from '@angular/common/http';
import { StockLogComponent } from './components/reports-components/stock-log/stock-log.component';
import { OrderLogComponent } from './components/reports-components/order-log/order-log.component';
import { OfferLogComponent } from './components/reports-components/offer-log/offer-log.component';
import localeTr from '@angular/common/locales/tr';
import { DasboardComponent } from './components/reports-components/dasboard/dasboard.component';
import { ProductMoveComponent } from './components/settings-componenets/product-move/product-move.component';
import { EditProductComponent } from './components/settings-componenets/edit-product/edit-product.component';
import { AddShielfComponent } from './components/add-new/add-shielf/add-shielf.component';
import { DeleteProductComponent } from './screens/delete-product/delete-product.component';
import { EditCostumerComponent } from './screens/edit-costumer/edit-costumer.component';
import { StockStatusComponent } from './screens/stock-status/stock-status.component';
import { UserSettingsComponent } from './screens/user-settings/user-settings.component';
import { MoveProductItemComponent } from './components/settings-componenets/move-product-item/move-product-item.component';
registerLocaleData(localeTr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProductListComponent,
    ProductItemComponent,
    TextColorDirective,
    UnlessDirective,
    NewItemComponent,
    NewStoreComponent,
    NewCostumerComponent,
    LoginComponent,
    LayoutComponent,
    Page404Component,
    AddStockComponent,
    ReportsComponent,
    CartComponent,
    SettingsComponent,
    OrderCompletedComponent,
    OfferComponent,
    SelectCostumerComponent,
    StockLogComponent,
    OrderLogComponent,
    OfferLogComponent,
    DasboardComponent,
    ProductMoveComponent,
    EditProductComponent,
    AddShielfComponent,
    DeleteProductComponent,
    EditCostumerComponent,
    StockStatusComponent,
    UserSettingsComponent,
    MoveProductItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MessageModule,
    NgxPrintModule,
    HttpClientModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'tr-TR' }, {provide: ErrorHandler, useClass: RaygunErrorHandler}],
  bootstrap: [AppComponent],
})
export class AppModule {}
