<div class="store-container">
  <div class="p-grid p-m-auto">
    <div class="p-sm-12">
      <h2>Dükkan Ayarları</h2>
      <p-divider></p-divider>
      <div class="p-grid p-justify-center p-mx-4">
        <p-dropdown
          [disabled]="!isStoreEditing"
          [style]="{ 'min-width': '250px', 'margin':'0 10px' }"
          [options]="state.storesGlobalStates"
          placeholder="Dükkan Seçiniz"
          [(ngModel)]="selectedStore"
          optionLabel="name"
        ></p-dropdown>
        <button
          *ngIf="isStoreEditing"
          pButton
          type="button"
          class="p-button-success"
          [label]="'Yeni Dükkan Ekle'"
          [icon]="'pi pi-plus'"
          (click)="addStore()"
        ></button>
      </div>
      <div *ngIf="selectedStore || !isStoreEditing">
        <div class="p-m-4">
          <div class="p-fluid p-my-4">
            <span class="p-float-label">
              <input
                [(ngModel)]="selectedStore.name"
                id="name"
                type="text"
                pInputText
              />
              <label for="name">Dükkan Adı</label>
            </span>
          </div>
        </div>
        <div class="p-m-4">
          <div class="p-fluid p-my-4">
            <span class="p-float-label">
              <input
                [(ngModel)]="selectedStore.description"
                id="description"
                type="text"
                pInputText
              />
              <label for="description">Açıklama</label>
            </span>
          </div>
        </div>
        <div class="p-m-4 p-grid p-justify-end">
          <button
            pButton
            class="p-button-warning p-mx-1"
            label="İptal"
            (click)="resetSelectedStore()"
          ></button>
          <button
            pButton
            class="p-button-danger p-mx-1"
            label="Dükkanı Sil"
            [disabled]="!selectedStore || !isStoreEditing"
            (click)="deleteStore()"
          ></button>
          <button
            (click)="handleAddStore()"
            pButton
            label="Kaydet/Güncelle"
            icon="pi pi-save"
            class="p-button-success p-mx-1"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
