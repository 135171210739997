import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrencyNew } from '../modals/currency-modal';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  API_URL = 'https://free.currconv.com/api/v7/convert?q=USD_TRY,EUR_TRY&compact=ultra&apiKey=7705d42c204166b855d5';
  constructor(private http: HttpClient) {}

  getAllCurrency(): Observable<CurrencyNew> {
    return this.http.get<CurrencyNew>(this.API_URL);
  }
}
