import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SharedService } from '../../services/shared.service';
import { TableService } from '../../services/table.service';
import {ProductPalletModal} from '../../modals/product.modal';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [MessageService],
})
export class ProductListComponent implements OnInit {
  constructor(
    public sharedService: SharedService,
    public tableService: TableService
  ) {}

  selectedProduct: any;

  ngOnInit(): void {}

  addToCart(product: any) {
    this.selectedProduct = product;
    this.sharedService.cartPopUp = true;
  }

  getPalets(locations:ProductPalletModal[]) {
    let result = '';
    locations.forEach((l) => {
      result += l.storeName + l.storeShelf;
    });
    return result;
  }
  exportExcel() {
    const exportList = this.tableService.arrayData.map((stock) => {
      return {
        Barkod: stock.barcodeCode,
        'Malzeme Adı': stock.name,
        'Alış Kuru': stock.costCurrency.name,
        Alış: stock.cost,
        'Eski Alış': stock.oldCost,
        Ürün: stock.unitType.name,
        'Ürün Birimi': stock.unitType.unit,
        'Kenar 1 / MM': stock.value1 ? stock.value1 : '',
        'Kenar 2 / MM': stock.value2 ? stock.value2 : '',
        'Kalınlık / MM': stock.value3 ? stock.value3 : '',
        KG: stock.kgPerUnit,
        'Sabit Çarpan': stock.unitType.carpan,
        'Stok Uyarı Miktarı': stock.minimumAmount,
        Paletler: this.getPalets(stock.pallets),
        'Stok Miktari': stock.isUnitExist.trim() === '-' ? 0 : stock.isUnitExist.substring(0, stock.isUnitExist.length - 3),
        'Stok Durumu': stock.stockStatusValue,
      };
    });
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(exportList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'urun_listesi');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      const EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
}
