<footer>
  <p-panel showHeader="false">
    <div class="p-grid p-jc-between">
      <h3>Özsoy Metal ve Paslanmaz SAN. LTD. ŞTİ.</h3>
      <img width="130px" height="40px" src="../../../assets/images/logo.jpg" alt="logo">
    </div>
    <p class="p-text-center">
      Stok Yönetim Uygulaması
      Tüm hakları saklıdır. (c)
    </p>
  </p-panel>
</footer>
