import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UsersModal } from '../modals/users.modal';

enum UserType {
  user = 'user',
  admin ='admin',
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<UsersModal>;
  userInfo: any;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // Logged in
        if (user) {
          return this.afs.doc<UsersModal>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  async createUser(email: string, password: string, role: UserType, name:string) {
   try {
      const response = await this.afAuth.createUserWithEmailAndPassword(email, password);
      return await this.updateUserData(response.user, role, name);
    } catch (er) {
      return console.warn(er);
    }
  }

 async changePassword(newPassord: string) {
   const user = await this.afAuth.currentUser;
   return user.updatePassword(newPassord)
  }

  private updateUserData(user, role:string, name:string) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<UsersModal> = this.afs.doc(
      `users/${user.uid}`
    );

    const data = {
      uid: user.uid,
      email: user.email,
      role,
      name
    };
    return userRef.set(data, { merge: true });
  }

  async signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then(async (result) => {
      this.userInfo = result.user;
      if (this.userInfo) {
        if (this.userInfo.role === 'admin') {
          this.router.navigate(['home'])
        } else {
          this.router.navigate(['stock-status'])
        }
      }

    });
  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(['/']).then();
  }
}
