<p-menubar [model]="menuItems">
  <ng-template pTemplate="start">
    <img alt="logo"
         [routerLink]="['/home']"
      src="../../../assets/images/logo.jpg"
      height="40px" class="p-mr-2 p-link" />
  </ng-template>
  <ng-template pTemplate="end">

    <p-button *ngIf="!isCartPage" (click)="goToCart()" label="Sepete Git" icon="pi pi-shopping-cart"
      styleClass="p-button-info" [badge]="cartService.getCartItems().length.toString()" badgeClass="p-badge-danger">
    </p-button>

    <button  pButton [disabled]="!sharedService.isStockLocked" *ngIf="isCartPage" (click)="completeOrder()" label="Siparişi Tamamla" icon="pi pi-shopping-cart"
      styleClass="p-button-info" badgeClass="p-badge-danger">
    </button>
  </ng-template>
</p-menubar>

<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
