import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

// tslint:disable-next-line:no-implicit-dependencies
import { v4 as uuidv4 } from 'uuid';
import { CartService } from '../../services/cart.service';
import { SharedService } from '../../services/shared.service';
import { SalesItemModal } from '../../modals/sales-item.modal';
import { CompanyInfo } from '../../utils/company-info';
import { StatesService } from '../../services/states.service';
@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.scss'],
  providers: [MessageService],
})
export class OrderCompletedComponent implements OnInit {
  @Input() isRePrint = false
  constructor(
    public cartService: CartService,
    public sharedService: SharedService,
    private messageService: MessageService,
    private router: Router,
    public stateService: StatesService
  ) {}
  printList: Array<SalesItemModal> = [];
  siparisNo: string;
  isLoading = false;
  companyInfo = {
    address: CompanyInfo.address,
    city: CompanyInfo.city,
    name: CompanyInfo.ticariIsim,
  };

  date:number;
  printStyle = {
    h3: {
      'text-align': 'center',
    },
    table: {
      width: '100%',
      'border-collapse': 'collapse',
    },
    tr: {
      width: '100%',
      'min-height': '100px',
      'border-bottom': '1px solid black',
      padding: '10px',
    },
    td: {
      'text-align': 'center',
      height: '60px',
      padding: '10px 0',
    },
    th: {
      'background-color': '#fde5c6',
    },
    '.quantity': {
      'font-weight': '900',
    },
    '.orderNo': {
      'font-weight': 'bold',
      'background-color': '#ffbdbd',
    },
    '.quate': {
      margin: '0 auto',
      'font-size': '0.8rem',
      'font-weight': 'bold',
      'text-align': 'center',
    },
    '.print-button': {
      display: 'none',
    },
    '.duplicate-screen': {
      'border-top': '2px dotted lightgray',
      'margin-top': '50px',
    },
  };
  ngOnInit(): void {
    this.date = Date.now();
    this.printList = this.cartService.getCartItems();
    this.cartService.cartUpdated.subscribe((response) => {
      this.printList = response;
    });
    this.siparisNo = uuidv4().slice(0, 8).toLocaleUpperCase();
  }

  async print() {
   if(!this.isRePrint) {
     this.isLoading = true;
     const result = await this.cartService.salesItems(this.siparisNo);
     if (!result) {
       this.sharedService.togglePrintPopUp();
       this.isLoading = false;
       await this.router.navigate(['home']);
     } else {
       this.messageService.add({
         severity: 'error',
         summary: 'Hata!',
         detail: 'Hata Alındı!',
       });
     }
   }
  }
  cancel(){
    if(this.isRePrint) {
      this.cartService.makeEmptyCart()
      this.sharedService.togglePrintPopUp()
    } else {
      this.sharedService.togglePrintPopUp()
    }
  }
}
