<p-dialog
  header="Sipariş Fişi"
  position="top"
  [(visible)]="sharedService.printPopUp"
>
  <div id="print-section" class="order-screen">
    <div>
      <div class='p-grid p-justify-between'>
        <img
          height="40px"
          width="130px"
          src="../../../assets/images/logo.jpg"
          alt="logo"
        />
        <h3>{{companyInfo.name}}</h3>
      <div class="p-grid p-justify-around">
        <h5>
          Sipariş No: <span class="orderNo">{{ siparisNo }}</span>
        </h5>
        <h5>
          Müşteri <span class="orderNo">{{ cartService.costumer?.name }}</span>
        </h5>
      </div>
      </div>
      <table>
        <tr>
          <th>Sıra</th>
          <th>Barkod</th>
          <th>Malzeme Adı</th>
          <th>Yer</th>
          <th>Toplam</th>
        </tr>
        <tr class="border-bottom" *ngFor="let item of printList; let i = index;">
          <td>{{i+1}}</td>
          <td>{{ item.item.barcodeCode }}</td>
          <td>{{ item.item.name }}</td>
          <td>
            <ng-template [ngIf]="!item.item.unitType.name.includes('Adet')">
              <p *ngFor="let l of item.locations">
              <span class="quantity"
              >{{ l.quantity  }} {{ item.item.unitType.symbol }}</span
              > -> {{ l.pallet.storeName }} / {{ l.pallet.storeShelf }}
              </p>
            </ng-template>
          </td>
          <td>
            <span class="quantity">{{ item.item.unitType.name === 'Adet' ? '-' :  item.item.unitType.name === 'Plaka' ? item.quantity + item.item.unitType.symbol : (item.quantity / item.item.perItem).toFixed(2) + ' Boy'}}</span>
          </td>
        </tr>
      </table>
      <div class="note">
        <p *ngIf="cartService.cartNote" class="note-text"><strong>Not: </strong> {{cartService.cartNote}}</p>
        <p class="note-text"><strong>İşlemi Yapan: </strong> {{stateService.user?.name}}</p>
      </div>
      <div class="footer">
        <p class="quate">Bu fiş fatura yerine geçmez. Sadece bilgilendirme amacıya düzenlenmiştir.</p>
        <p class="quate">{{ date | date: 'medium' }}</p>
      </div>
    </div>
  </div>
  <div class="print-button">
    <button
      [printStyle]="printStyle"
      (click)="print()"
      printSectionId="print-section"
      ngxPrint
      pButton
      label="Satışı işle ve Yazdır"
    ></button>
    <button
      (click)="cancel()"
      pButton
      class="p-button-danger"
      label="İptal et"
    ></button>
  </div>
</p-dialog>
<p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
<p-toast></p-toast>
