<div class="container">
  <h2>Ürün Düzenle</h2>
  <p-divider></p-divider>
  <div class="p-fluid p-my-4 p-input-filled p-lg-12">
      <span class="p-float-label">
        <p-dropdown
          placeholder="Ürün Seçiniz"
          [showTransitionOptions]="'100ms'"
          scrollHeight="150px"
          [hideTransitionOptions]="'100ms'"
          required
          [virtualScroll]="true"
          itemSize="30"
          [options]="products"
          [filter]="true"
          [(ngModel)]="selectedProduct"
          (click)="getProduct()"
          optionLabel="name"
          emptyFilterMessage="Ürün Bulunamadı!"
        ></p-dropdown>
      </span>
  </div>

  <form
    *ngIf="selectedProduct"
    [formGroup]="newProductFrom"
    class="content"
    (ngSubmit)="updateItem()"
  >
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="barcodeCode"
          id="barcodeCode"
          type="text"
          pInputText
        />
        <label for="barcodeCode">Barkod Kodu</label>
      </span>
    </div>

    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="name"
          id="name"
          type="text"
          pInputText
        />
        <label for="name">Malzeme Adı</label>
      </span>
    </div>
    <div class="p-grid p-my-4">
      <div class="p-fluid p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <p-dropdown
            required
            [options]="shared.units"
            formControlName="unitType"
            optionLabel="name"
            id="unit"
          ></p-dropdown>
          <label for="unit">Ürün Tipi</label>
        </span>
      </div>
      <div class="p-fluid p-input-filled p-sm-12 p-lg-6">
        <span class="p-float-label">
          <p-dropdown
            placeholder="Kuru Seçiniz"
            required
            [options]="shared.currencies"
            formControlName="costCurrency"
            optionLabel="name"
            id="costCurrency"
          ></p-dropdown>
          <label for="costCurrency">Kur</label>
        </span>
      </div>
    </div>
    <div class="p-grid">
      <ng-template
        [ngIf]="selectedProduct.unitType.input === 1"
        class="p-grid p-my-4"
      >
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-12">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first3"
              type="number"
              pInputText
            />
            <label for="first">{{
              selectedProduct.value1
              }}</label>
          </span>
        </div>
      </ng-template>
      <ng-template
        [ngIf]="selectedProduct.unitType.input === 2"
        class="p-grid p-my-4"
      >
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first"
              type="number"
              pInputText
            />
            <label for="first">{{
              selectedProduct.value1
              }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-6">
          <span class="p-float-label">
            <input
              formControlName="value2"
              id="second"
              type="number"
              pInputText
            />
            <label for="second">{{
              selectedProduct.value2
              }}</label>
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="selectedProduct.unitType.input=== 3">
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value1"
              id="first1"
              type="number"
              pInputText
            />
            <label for="first1">{{
              selectedProduct.value1
              }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value2"
              id="second1"
              type="number"
              pInputText
            />
            <label for="second1">{{
              selectedProduct.value2
              }}</label>
          </span>
        </div>
        <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
          <span class="p-float-label">
            <input
              formControlName="value3"
              id="tick"
              type="number"
              pInputText
            />
            <label for="tick">{{
              selectedProduct.value3
              }}</label>
          </span>
        </div>
      </ng-template>
    </div>
    <div class="p-lg-12 p-text-center">
      <p-button
        styleClass="p-button-secondary"
        (click)="hesapla()"
        class="p-button-secondary"
        label="Hesapla"
      ></p-button>
    </div>
    <div *ngIf="newProductFrom.get('unitType').value?.name !== 'Adet' && newProductFrom.get('unitType').value?.name !== 'Plaka'" class="p-grid p-my-4 p-align-center">
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-12">
        <span class="p-float-label">
          <input
            formControlName="perItem"
            id="perItem"
            type="number"
            pInputText
          />
          <label for="perItem">Birim başına uzunluk(MM)</label>
        </span>
      </div>
    </div>
    <div class="p-grid p-my-4">
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
        <span class="p-float-label">
          <input
            required
            id="unitType"
            type="number"
            formControlName="minimumAmount"
            pInputText
          />
          <label for="unitType">Uyarı Miktarı</label>
          <span class="p-inputgroup-addon">{{
            selectedProduct.unitType?.symbol
            }}</span>
        </span>
      </div>
      <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
        <span class="p-float-label">
          <input
            required
            id="cost"
            type="number"
            formControlName="cost"
            pInputText
            step='0.01'
          />
          <label for="cost"
          >Maliyet

          </label>
              <span class="p-inputgroup-addon">{{
                selectedProduct?.costCurrency.abbs
                }}</span>
        </span>
      </div>
    <div class="p-fluid p-inputgroup p-input-filled p-sm-12 p-lg-4">
        <span class="p-float-label">
          <input
            disabled
            required
            id="kg"
            type="number"
            formControlName="kgPerUnit"
            pInputText
          />
          <label for="kg"
          >{{selectedProduct.unitType.unit }} Başına KG
          </label>
        </span>
    </div>
    </div>
    <div class="p-mt-5 p-grid p-justify-end">
      <p-button
        styleClass="p-button-success"
        [disabled]="newProductFrom.pristine"
        type="submit"
        class="p-m-2"
        label="Güncelle"
      ></p-button>
    </div>
  </form>

</div>
<p-toast></p-toast>
