import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import {
  ProductModal,
  ProductPalletModal,
} from '../../../modals/product.modal';
import { Pallet, StoreModal } from '../../../modals/store.modal';
import { MessageService } from 'primeng/api';
import { FirebaseService } from '../../../services/firebase.service';
import { StatesService } from '../../../services/states.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss'],
  providers: [MessageService],
})
export class AddStockComponent implements OnInit {
  pallets: Array<Pallet>;
  shelf: Array<any>;
  selectedStore: StoreModal;
  selectedProduct: ProductModal;
  selectedShelf: Pallet;
  amount: number;
  cost: number;

  constructor(
    public sharedService: SharedService,
    private messageService: MessageService,
    private firebaseService: FirebaseService,
    public state: StatesService
  ) {}

  ngOnInit(): void {}

  checkFormValid(): boolean {
    return (
      !this.selectedStore ||
      !this.selectedProduct ||
      !this.selectedShelf ||
      !this.amount ||
      !this.cost
    );
  }

  handleAddStock() {
    const pallet: ProductPalletModal = {
      id: this.selectedShelf.id,
      amountUnit: this.amount,
      storeName: this.selectedStore.name,
      storeShelf: this.selectedShelf.name,
    };
    this.selectedProduct.oldCost = this.selectedProduct.oldCost
      ? this.selectedProduct.oldCost
      : this.cost;
    this.firebaseService
      .addStock({ ...this.selectedProduct, cost: this.cost }, pallet)
      .then(() => {
        this.firebaseService
          .addStockLog({ ...this.selectedProduct, cost: this.cost }, pallet)
          .then(() => {
            this.messageService.add({
              detail: `${this.selectedProduct.name} için ${this.amount} adet stok girişi yapıldı.`,
              summary: 'Stok girişi yapıldı.',
              closable: true,
              severity: 'success',
            });

            this.selectedProduct = null;
            this.selectedShelf = null;
            this.selectedStore = null;
            this.amount = null;
          });
      })
      .catch((err) => {
        this.messageService.add({
          detail: `${err}`,
          summary: 'Hata!',
          severity: 'error',
        });
      });
  }
}
