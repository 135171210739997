<div>
  <div class="selection-container">
    <button
      (click)="settingForm.reset(); isPasswordChange = false"
      pButton
      label="Yeni Kullanıcı Ekle"
    ></button>
    <button
      style="margin-left: 20px"
      class="p-button-info"
      (click)="changeScreen()"
      pButton
      label="Şifre Güncelle"
    ></button>
  </div>
  <form
    *ngIf="!isPasswordChange"
    [formGroup]="settingForm"
    class="content"
    (ngSubmit)="createUser()"
  >
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="email"
          id="email"
          type="email"
          pInputText
        />
        <label for="email">E-Mail Adresi</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="password"
          id="password"
          type="password"
          pInputText
        />
        <label for="password">Şifre</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          required
          formControlName="name"
          id="name"
          type="text"
          pInputText
        />
        <label for="name">İsim Soyisim</label>
      </span>
    </div>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <p-dropdown
          id="role"
          inputId="role"
          [autoDisplayFirst]="false"
          [options]="Roles"
          formControlName="role"
          optionLabel="name"
          optionValue="name"
        ></p-dropdown>
        <label for="role">Rol Seçiniz</label>
      </span>
    </div>

    <div class="p-mx-5 p-grid p-justify-end">
      <p-button
        (click)="resetForm()"
        styleClass="p-button-warning"
        class="p-m-2"
        label="İptal"
      ></p-button>
      <p-button
        styleClass="p-button-success"
        [disabled]="settingForm.invalid"
        type="submit"
        class="p-m-2"
        label="Kaydet"
      ></p-button>
    </div>
  </form>
  <div *ngIf="isPasswordChange" class="change-pasword">
    <h4>Kullanıcı: {{ state.user.email }}</h4>
    <div class="p-fluid p-my-4 p-input-filled">
      <span class="p-float-label">
        <input
          [(ngModel)]="newPassword"
          id="newPassword"
          type="password"
          pInputText
        />
        <label for="newPassword">Yeni Şifre</label>
      </span>
    </div>
    <div class="p-mx-5 p-grid p-justify-end">
      <button
        pButton
        (click)="changeMyPassword()"
        label="Şifremi Değiştir"
      ></button>
    </div>
  </div>
</div>

<p-toast></p-toast>
