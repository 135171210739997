import { Component, OnInit } from '@angular/core';
import { CostumerModal } from '../../modals/costumer.modal';
import { CartService } from '../../services/cart.service';
import { MessageService } from 'primeng/api';
import { StatesService } from '../../services/states.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-select-costumer',
  templateUrl: './select-costumer.component.html',
  styleUrls: ['./select-costumer.component.scss'],
  providers: [MessageService],
})
export class SelectCostumerComponent implements OnInit {
  constructor(
    public cartService: CartService,
    private message: MessageService,
    public stateService: StatesService,
    public sharedService: SharedService
  ) {}
  selectedCustumer: CostumerModal;
  note: string;
  ngOnInit(): void {
    this.note = this.cartService.cartNote;
    this.selectedCustumer = this.cartService.costumer;
  }
  saveNoteAndCostumer() {
    if (this.selectedCustumer) {
      this.cartService.costumer = this.selectedCustumer;
    }
    if (this.note) {
      this.cartService.cartNote = this.note;
    }

    this.message.add({
      severity: 'success',
      summary: 'Eklendi',
      detail: 'Bilgiler kayedildi.',
    });

    this.sharedService.toggleSelectCostumer();
  }
}
