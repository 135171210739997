import { Injectable } from '@angular/core';
import { ProductTypes } from '../utils/product-types';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CalculateService {
  constructor() {}

  // Bu servis, ütün tipitine göre 1Mt uzunluğun kaç KG olduğunu hesaplamaktadır.
  hesapla(form) {
    const { value } = form.get('unitType');
    switch (value.name) {
      case ProductTypes.MIL:
        this.calculateMil(form);
        break;
      case ProductTypes.PLAKA:
        this.calculatePlaka(form);
        break;
      case ProductTypes.KARE:
        this.calculateKare(form);
        break;
      case ProductTypes.LAMA:
        this.calculateLama(form);
        break;
      case ProductTypes.KOSEBENT:
        this.calculateKosebant(form);
        break;
      case ProductTypes.PROFIL:
        this.calculateProfil(form);
        break;
      case ProductTypes.BORU:
        this.calculateBoru(form);
        break;
      case ProductTypes.ADET:
        this.calculateAdet(form);
        break;
    }
  }

  calculateMil(form: FormGroup) {
    // çap*çap*boy*carpan
    const { value } = form.get('unitType');
    const cap = +form.get('value1').value;
    const result = cap * cap * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateLama(form: FormGroup) {
    // a*a*boy-carpan
    const { value } = form.get('unitType');
    const kenar = +form.get('value1').value;
    const kenar2 = +form.get('value2').value;
    const result = kenar * kenar2 * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateKare(form: FormGroup) {
    // a*a*boy-carpan
    const { value } = form.get('unitType');
    const kenar = +form.get('value1').value;
    const result = kenar * kenar * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }
  calculateAltiKose(form: FormGroup) {
    // a*a*boy*carpan
    const { value } = form.get('unitType');
    const kenar = +form.get('value1').value;
    const result = kenar * kenar * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateBoru(form: FormGroup) {
    // (cap-kalinlik)*kalinlik*carpan
    const { value } = form.get('unitType');
    const cap = +form.get('value1').value;
    const kalinlik = +form.get('value2').value;
    const result = (cap - kalinlik) * kalinlik * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateProfil(form: FormGroup) {
    // 2(a+b)*kalinlik*carpan
    const { value } = form.get('unitType');
    const yukseklik = +form.get('value1').value;
    const genislik = +form.get('value2').value;
    const kalinlik = +form.get('value3').value;
    const result = ((yukseklik + genislik) * 2 )* kalinlik * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculatePlaka(form: FormGroup) {
    // a*b*kalinlik*carpan
    const { value } = form.get('unitType');
    const genislik = +form.get('value1').value;
    const uzunluk = +form.get('value2').value;
    const kalinlik = +form.get('value3').value;
    const result = genislik * uzunluk * kalinlik * value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateKosebant(form: FormGroup) {
    // (a+b)*carpan

    const { value } = form.get('unitType');
    const kenar1 = +form.get('value1').value;
    const kenar2 = +form.get('value2').value;
    const kalinlik = +form.get('value3').value;
    const result = (kenar1 + kenar2) * kalinlik * +value.carpan;
    form.get('kgPerUnit').setValue(result);
  }

  calculateAdet(form: FormGroup) {
    // (a+b)*carpan

    const adet = +form.get('value1').value;
    const result = adet;
    form.get('kgPerUnit').setValue(result);
  }
}
